import Layout from '../../components/layout/Layout';
import { styled } from '@mui/material/styles';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuth } from '../../auth/hooks/useAuth';
import useUserInfoLocal from '../../adapters/hooks/user/useUserInfoLocal';
import Avatar from '../../components/user/Avatar';
import ProfileEdit from './components/ProfileEdit';
import ProfileDetail from './components/ProfileDetail';
import { useGetUserProfileHubLazy } from '../../adapters/hooks/user/useGetUserProfileHub';
import UserProfileDTO from '../../adapters/dto/user/UserProfileDTO';
import ProfileAvatar from './components/ProfileAvatar';

const PREFIX = 'Profile';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  header: `${PREFIX}-header`,
  avatar: `${PREFIX}-avatar`,
  tabSelection: `${PREFIX}-tabSelection`,
};

const StyledLayout = styled(Layout)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },

  [`& .${classes.card}`]: {
    width: '50%',
    minHeight: '40vh',
  },

  [`& .${classes.header}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },

  [`& .${classes.avatar}`]: {
    paddingBottom: theme.spacing(2),
    cursor: 'pointer',
  },

  [`& .${classes.tabSelection}`]: {
    paddingLeft: '44px',
  },
}));

function Profile() {
  const [username, setUserName] = useState<string>();
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState<string>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [userProfile, setUserProfile] = useState<UserProfileDTO>();
  const [avatarEditOpen, setAvatarEditOpen] = useState(false);
  const { loggedInUser } = useAuth();
  const { getUserInfo } = useUserInfoLocal();
  const { execute: getUserProfile } = useGetUserProfileHubLazy();

  useEffect(() => {
    if (loggedInUser) {
      setUserName(loggedInUser.username);
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (username) {
      getUserInfo(username).then((ui) => {
        setDisplayName(ui.nickname);
        setAvatarUrl(ui.avatarUrl);
      });
    }
  }, [getUserInfo, username]);

  useEffect(() => {
    getUserProfile().then((response) => {
      setUserProfile(response.userProfile);
    });
  }, [getUserProfile]);

  const selectTab = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };

  const cancel = () => {
    setEditMode(false);
  };

  const saved = (profile: UserProfileDTO) => {
    setEditMode(false);
    setUserProfile(profile);
  };

  const avatarEdit = () => {
    setAvatarEditOpen(true);
  };

  const closeEditAvatar = async (updated: boolean) => {
    if (updated && username) {
      const ui = await getUserInfo(username, true);
      setDisplayName(ui.nickname);
      setAvatarUrl(ui.avatarUrl);
    }

    setAvatarEditOpen(false);
  };

  return (
    <StyledLayout>
      <Box className={classes.root}>
        <Paper className={classes.card}>
          <Box className={classes.header}>
            <Box className={classes.avatar} onClick={avatarEdit}>
              <Avatar url={avatarUrl} showOutline={true} large={false} />
            </Box>
            <Typography variant="h6">{displayName}</Typography>
          </Box>
          <Tabs
            value={selectedTab}
            onChange={tabChange}
            className={classes.tabSelection}>
            <Tab label="Details" {...selectTab(0)} />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            {!editMode && userProfile && (
              <ProfileDetail userProfile={userProfile} edit={setEditMode} />
            )}
            {editMode && userProfile && (
              <ProfileEdit
                profile={userProfile}
                cancel={cancel}
                saved={saved}
              />
            )}
          </TabPanel>
        </Paper>
      </Box>
      <ProfileAvatar
        open={avatarEditOpen}
        close={closeEditAvatar}
        url={avatarUrl}
      />
    </StyledLayout>
  );
}

export default Profile;
