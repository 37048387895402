export enum Routes {
  Login = '/login',
  Join = '/join',
  Availability = '/availability',
  ForgottenPassword = '/forgotten-password',
  ActivationCode = '/activation-code/:activationCode',
  ResetPassword = '/reset-password/:token',
  Home = '/home',
  Profile = '/profile',
  UserManagement = '/user-management',
  AuditLog = '/audit-log',
  Productions = '/productions',
  Crew = '/crew',
}
