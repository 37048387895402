import Layout from '../../components/layout/Layout';
import { Box } from '@mui/material';

function Home() {
  return (
    <Layout>
      <Box></Box>
    </Layout>
  );
}

export default Home;
