import useApi, { ApiOptions } from './base/useApi';

export interface RequestPasswordResetParams {
  username: string;
}

export interface RequestPasswordResetResult {}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/request-password-reset',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useRequestPasswordResetLazy = (
  params?: RequestPasswordResetParams
) => {
  return useApi<RequestPasswordResetParams, RequestPasswordResetResult>(
    options,
    params
  );
};

export default useRequestPasswordResetLazy;
