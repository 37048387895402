
import { Box, Button, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Layout from '@src/components/layout/Layout';
import { useEffect, useState } from 'react';
import ProductionList from './components/ProductionList';
import ProductionAdd from './components/ProductionAdd';

const PREFIX = 'Template';

const classes = {
  pageTitle: `${PREFIX}-pageTitle`,
  paper: `${PREFIX}-information`,
};

const StyledTitle = styled(Box)(({ theme }) => ({
  padding: '0 20px 20px 20px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between'
}));

function Productions() {
  // const { execute: userFind } = useFindUsersLazy();
  // const [disabled, setDisabled] = useState(false);
  // const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  useEffect(() => {}, []);

  const handleNewClick = () => {
    setOpen(true);
  }

  
  const handleClose = () => {
    setOpen(false);
  }


  return (
    <Layout>
      <StyledTitle >
        <Typography variant="h4">Productions</Typography>
        <Button onClick={handleNewClick} color="secondary" variant="contained" size="medium">
          New Production
        </Button>
      </StyledTitle>
      <Paper elevation={8} className={classes.paper}>
        <ProductionList />
      </Paper>
      <ProductionAdd open={open} close={handleClose} />
    </Layout>
  );
}

export default Productions;