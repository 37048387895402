import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import ProductionDTO from '@src/adapters/dto/production/ProductionDTO';

const PREFIX = 'PreferenceItemDetail';

const classes = {
  title: `${PREFIX}-title`,
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.container}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.item}`]: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  [`& .${classes.buttons}`]: {
    flex: '1',
    marginRight: '8px',
  },
  [`& .${classes.button}`]: {
    flex: '1',
    marginLeft: '8px',
  },
}));

interface Props {
  close(note: ProductionDTO | undefined): void;
  open: boolean;
}

function ProductionAdd({ close, open }: Props) {
  //   const { execute: saveNote } = useSavePreferenceNoteLazy();
  // const [production, setProduction] = useState<ProductionDTO>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProductionDTO>();

  const onSubmit = async (data: ProductionDTO) => {
    // const result = await saveNote({
    //   note: data,
    //   preferenceId: preferenceId,
    // });
    // if (result.note) {
    //   reset();
    //   close(result.note);
    // }
  };

  const handleClose = () => {
    reset();
    close(undefined);
  };

  // useEffect(() => {
  //   reset(production);
  // }, [reset, production]);

  return (
    <StyledDialog
      open={open}
      aria-labelledby="Note Details"
      aria-describedby="Note Details"
      fullWidth={true}
      maxWidth={'md'}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography variant="h6">Add Production</Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.container}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField {...field} label="Name" className={classes.item} />
            )}
          />
          {errors.name && errors.name.message}
          {/* <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="Type" className={classes.item} />
              )}
            />
            {errors.type && <p>{errors.type.message}</p>} */}
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            className={classes.button}>
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            className={classes.button}>
            Save
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
}

export default ProductionAdd;
