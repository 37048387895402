import { Box, CardHeader, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DepartmentDTO from '@src/adapters/dto/department/DepartmentDTO';

interface Props {
  checkChanged(id: string, checked: boolean): void;
  checked: boolean;
  department: DepartmentDTO;
}

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background:
    'linear-gradient(0deg, #0B1928, #0B1928), linear-gradient(90deg, #111114 0%, #161516 100%)',
  width: '358px',
  height: '74px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderRadius: '16px',
  marginBottom: theme.spacing(2),
}));

function Department({ department, checkChanged, checked }: Props) {
  const handleClick = () => {
    checkChanged(department.id, !checked);
  };

  return (
    <MainContainer onClick={handleClick} key={department.id}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            paddingRight: '10px',
          }}>
          <CardHeader
            variant="rounder"
            sx={{
              background: 'rgba(123, 199, 231, 0.08)',
              width: '40px',
              height: '40px',
            }}></CardHeader>
        </Box>
        <Typography variant="body1">{department.name}</Typography>
      </Box>
      <Checkbox checked={checked}></Checkbox>
    </MainContainer>
  );
}

export default Department;
