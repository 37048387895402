import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserRegisterDTO from '@src/adapters/dto/user/UserRegisterDTO';
import { useRegisterUserValidateLazy } from '@src/adapters/hooks/user/useRegisterUserValidate';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  onContinue(user: UserRegisterDTO): void;
  user: UserRegisterDTO | undefined;
}

const PREFIX = 'register-information';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  form: `${PREFIX}-form`,
};

interface UserRegisterForm extends UserRegisterDTO {
  confirmPassword: string;
}

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [`& .${classes.form}`]: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    padding: '20px',
  },
  [`& .${classes.form} .MuiTextField-root`]: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

function Information({ onContinue, user }: Props) {
  const { execute: validate } = useRegisterUserValidateLazy();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<UserRegisterForm>({
    defaultValues: {
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      mobileNumber: user ? user.mobileNumber : '',
      password: user ? user.password : '',
      confirmPassword: user ? user.password : '',
    },
  });

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  const password = watch('password');

  const onSubmit = async (data: UserRegisterDTO) => {
    validate({ user: data }).then((result) => {
      if (result.checked) {
        onContinue(data);
      }
    });
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.title}>
        Join Glue Crew
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        We need a few details to get your account set up
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Controller
          name="firstName"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="First name"
              error={!!errors.firstName}
              autoComplete="new-password"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Last name"
              error={!!errors.lastName}
              autoComplete="new-password"
            />
          )}
        />
        <Controller
          name="mobileNumber"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.mobileNumber}
              label="Mobile Number"
              autoComplete="new-password"
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              label="Password"
              error={!!errors.password}
              autoComplete="new-password"
            />
          )}
        />
        <Controller
          name="confirmPassword"
          control={control}
          rules={{
            validate: (value) => value === password || 'Passwords do not match',
            required: 'This field is required',
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              label="Confirm Password"
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword ? errors.confirmPassword.message : ''
              }
              autoComplete="new-password"
            />
          )}
        />
        <Button type="submit" variant="contained" fullWidth={true}>
          Continue
        </Button>
      </form>
    </MainContainer>
  );
}

export default Information;
