export const AvailabilityState = {
  Available: 'Available',
  NotAvailable: 'NotAvailable',
  Pencilled: 'Pencilled',
  Booked: 'Booked',
  NotSet: 'NotSet',
} as const;

type AvailabilityStateKey = keyof typeof AvailabilityState;
export type AvailabilityStateType =
  (typeof AvailabilityState)[AvailabilityStateKey];

export const AvailabilityStates: AvailabilityStateType[] =
  Object.values(AvailabilityState);
