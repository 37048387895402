export enum EventLogType {
  Admin_Login = 'Admin_Login',
  Admin_Logout = 'Admin_Logout',
  Admin_User_Added = 'Admin_User_Added',
  Admin_User_Deactivated = 'Admin_User_Deactivated',
  Admin_User_Activated = 'Admin_User_Activated',
  Admin_Role_Added = 'Admin_Role_Added',
  Admin_Role_Removed = 'Admin_Role_Removed',
  App_User_SkippedAge = 'App_User_SkippedAge',
  App_Therapy_Rescheduled = 'App_Therapy_Rescheduled',
  App_Therapy_Cancelled = 'App_Therapy_Cancelled',
  Content_Add_Item = 'Content_Add_Item',
  Content_Add_Thumbnail = 'Content_Add_Thumbnail',
  Content_Update_Item = 'Content_Update_Item',
  Content_Send_For_Review = 'Content_Send_For_Review',
  Content_Publish = 'Content_Publish',
  Content_Reject = 'Content_Reject',
  Content_Recovered_From_Moderated = 'Content_Recovered_From_Moderated',
  Content_Unpublish = 'Content_Unpublish',
  Content_Archive = 'Content_Archive',
  Content_Recovered_From_Archived = 'Content_Recovered_From_Archived',
  Content_Add_Creator = 'Content_Add_Creator',
  Content_Add_Category = 'Content_Add_Category',
  Content_Update_Category = 'Content_Update_Category',
  Content_Create_Tag = 'Content_Create_Tag',
  Content_Add_Tag = 'Content_Add_Tag',
  Content_Remove_Tag = 'Content_Remove_Tag',
  Helpdesk_Added_To_Urgent = 'Helpdesk_Added_To_Urgent',
  Helpdesk_Added_To_NonUrgent = 'Helpdesk_Added_To_NonUrgent',
  Helpdesk_Add_Note = 'Helpdesk_Add_Note',
  Helpdesk_Topic_Added = 'Helpdesk_Topic_Added',
  Helpdesk_Topic_Removed = 'Helpdesk_Topic_Removed',
  Helpdesk_Assign_NonUrgent = 'Helpdesk_Assign_NonUrgent',
  Helpdesk_Assign_Urgent = 'Helpdesk_Assign_Urgent',
  Helpdesk_Book_Session = 'Helpdesk_Book_Session',
  Helpdesk_Cancel_Session = 'Helpdesk_Cancel_Session',
  Helpdesk_Start_Session = 'Helpdesk_Start_Session',
  Helpdesk_Close_Conversation_Confirm = 'Helpdesk_Close_Conversation_Confirm',
  Helpdesk_Create_Internal_Chat = 'Helpdesk_Create_Internal_Chat',
  Helpdesk_Create_Safeguarding_Concern_Report = 'Helpdesk_Create_Safeguarding_Concern_Report',
  Helpdesk_Invite_Supervisor = 'Helpdesk_Invite_Supervisor',
  Helpdesk_Risk_Assessment_Created = 'Helpdesk_Risk_Assessment_Created',
  Helpdesk_Strength_Plan_Created = 'Helpdesk_Strength_Plan_Created',
  Helpdesk_Strength_Plan_Deleted = 'Helpdesk_Strength_Plan_Deleted',
  Helpdesk_Strength_Plan_Close = 'Helpdesk_Strength_Plan_Close',
  Helpdesk_Strength_Plan_Publish = 'Helpdesk_Strength_Plan_Publish',
  Helpdesk_Reassign = 'Helpdesk_Reassign',
  Helpdesk_Reveal_Personal_Information = 'Helpdesk_Reveal_Personal_Information',
  Helpdesk_Send_To_Waiting = 'Helpdesk_Send_To_Waiting',
  Helpdesk_View_Conversation = 'Helpdesk_View_Conversation',
  Helpdesk_View_Other_Therapist = 'Helpdesk_View_Other_Therapist',
  HelpZone_View_Item = 'HelpZone_View_Item',
  Moderation_Set_Available = 'Moderation_Set_Available',
  Moderation_Set_Unavailable = 'Moderation_Set_Unavailable',
  Moderation_Assigned_Report = 'Moderation_Assigned_Report',
  Moderation_View_Journal = 'Moderation_View_Journal',
  Moderation_View_Report = 'Moderation_View_Report',
  Moderation_Submit_Report = 'Moderation_Submit_Report',
  Moderation_Requeue_Report = 'Moderation_Requeue_Report',
  Moderation_Update_Response = 'Moderation_Update_Response',
  Moderation_Added_Response = 'Moderation_Added_Response',
  Moderation_Disabled_Responses = 'Moderation_Disabled_Responses',
  Moderation_Enabled_Responses = 'Moderation_Enabled_Responses',
  Moderation_Added_Term = 'Moderation_Added_Term',
  Moderation_Updated_Term = 'Moderation_Updated_Term',
  Moderation_Archived_Term = 'Moderation_Archived_Term',
  Moderation_Restored_Term = 'Moderation_Restored_Term',
  Navigation_Help_Desk = 'Navigation_Help_Desk',
  Navigation_Help_Zone = 'Navigation_Help_Zone',
  Profile_Update = 'Profile_Update',
  RiskAssessment_Complete = 'RiskAssessment_Complete',
  RiskAssessment_Delete = 'RiskAssessment_Delete',
  RiskAssessmentTemplate_Delete = 'RiskAssessmentTemplate_Delete',
  Safeguarding_View_List = 'Safeguarding_View_List',
  Safeguarding_View_Report = 'Safeguarding_View_Report',
  Safeguarding_Save_Report = 'Safeguarding_Save_Report',
  Safeguarding_Raise_Report = 'Safeguarding_Raise_Report',
  Safeguarding_Request_Review = 'Safeguarding_Request_Review',
  Security_Login_Failed = 'Security_Login_Failed',
  Security_User_Activated = 'Security_User_Activated',
  Security_User_Activation_Failed_Email = 'Security_User_Activation_Failed_Email',
  Security_User_Activation_Failed_Code = 'Security_User_Activation_Failed_Code',
  Security_User_ResetPassword_Code_Account_Not_Found = 'Security_User_ResetPassword_Code_Account_Not_Found',
  Security_User_ResetPassword_Code_Expired = 'Security_User_ResetPassword_Failed_Code',
  TherapistSlot_Added = 'TherapistSlot_Added',
  TherapistSlot_Cancel = 'TherapistSlot_Cancel',
  TherapistSlot_Booked_Reassign = 'TherapistSlot_Booked_Reassign',
  TherapistSlot_Delete = 'TherapistSlot_Delete',
  TherapistSlot_Free_Reassign = 'TherapistSlot_Free_Reassign',
  TherapistSlot_Update = 'TherapistSlot_Update',
  Lifering_Update_ForceOutOfHours = 'Lifering_Update_ForceOutOfHours',
}

export const EventLogTypes = [
  EventLogType.Admin_Login,
  EventLogType.Admin_Logout,
  EventLogType.Admin_User_Added,
  EventLogType.Admin_User_Deactivated,
  EventLogType.Admin_User_Activated,
  EventLogType.Admin_Role_Added,
  EventLogType.Admin_Role_Removed,
  EventLogType.App_User_SkippedAge,
  EventLogType.App_Therapy_Rescheduled,
  EventLogType.App_Therapy_Cancelled,
  EventLogType.Content_Add_Item,
  EventLogType.Content_Add_Thumbnail,
  EventLogType.Content_Update_Item,
  EventLogType.Content_Send_For_Review,
  EventLogType.Content_Publish,
  EventLogType.Content_Reject,
  EventLogType.Content_Unpublish,
  EventLogType.Content_Archive,
  EventLogType.Content_Recovered_From_Archived,
  EventLogType.Content_Recovered_From_Moderated,
  EventLogType.Content_Add_Creator,
  EventLogType.Content_Add_Category,
  EventLogType.Content_Update_Category,
  EventLogType.Content_Create_Tag,
  EventLogType.Content_Add_Tag,
  EventLogType.Content_Remove_Tag,
  EventLogType.Helpdesk_Added_To_Urgent,
  EventLogType.Helpdesk_Added_To_NonUrgent,
  EventLogType.Helpdesk_Add_Note,
  EventLogType.Helpdesk_Topic_Added,
  EventLogType.Helpdesk_Topic_Removed,
  EventLogType.Helpdesk_Assign_NonUrgent,
  EventLogType.Helpdesk_Assign_Urgent,
  EventLogType.Helpdesk_Book_Session,
  EventLogType.Helpdesk_Cancel_Session,
  EventLogType.Helpdesk_Start_Session,
  EventLogType.Helpdesk_Close_Conversation_Confirm,
  EventLogType.Helpdesk_Create_Internal_Chat,
  EventLogType.Helpdesk_Create_Safeguarding_Concern_Report,
  EventLogType.Helpdesk_Invite_Supervisor,
  EventLogType.Helpdesk_Risk_Assessment_Created,
  EventLogType.Helpdesk_Strength_Plan_Created,
  EventLogType.Helpdesk_Strength_Plan_Deleted,
  EventLogType.Helpdesk_Reassign,
  EventLogType.Helpdesk_Reveal_Personal_Information,
  EventLogType.Helpdesk_Send_To_Waiting,
  EventLogType.Helpdesk_View_Conversation,
  EventLogType.Helpdesk_View_Other_Therapist,
  EventLogType.HelpZone_View_Item,
  EventLogType.Moderation_Set_Available,
  EventLogType.Moderation_Set_Unavailable,
  EventLogType.Moderation_View_Journal,
  EventLogType.Moderation_View_Report,
  EventLogType.Moderation_Submit_Report,
  EventLogType.Moderation_Assigned_Report,
  EventLogType.Moderation_Added_Response,
  EventLogType.Moderation_Disabled_Responses,
  EventLogType.Moderation_Enabled_Responses,
  EventLogType.Moderation_Update_Response,
  EventLogType.Moderation_Added_Term,
  EventLogType.Moderation_Updated_Term,
  EventLogType.Moderation_Archived_Term,
  EventLogType.Moderation_Restored_Term,
  EventLogType.Navigation_Help_Desk,
  EventLogType.Navigation_Help_Zone,
  EventLogType.RiskAssessment_Complete,
  EventLogType.RiskAssessment_Delete,
  EventLogType.Safeguarding_View_List,
  EventLogType.Safeguarding_View_Report,
  EventLogType.Safeguarding_Save_Report,
  EventLogType.Safeguarding_Raise_Report,
  EventLogType.Safeguarding_Request_Review,
  EventLogType.Security_Login_Failed,
  EventLogType.Security_User_Activated,
  EventLogType.Security_User_Activation_Failed_Email,
  EventLogType.Security_User_Activation_Failed_Code,
  EventLogType.Helpdesk_Strength_Plan_Close,
  EventLogType.Helpdesk_Strength_Plan_Publish,
  EventLogType.Profile_Update,
  EventLogType.TherapistSlot_Added,
  EventLogType.TherapistSlot_Cancel,
  EventLogType.TherapistSlot_Booked_Reassign,
  EventLogType.TherapistSlot_Delete,
  EventLogType.TherapistSlot_Free_Reassign,
  EventLogType.TherapistSlot_Update,
  EventLogType.Lifering_Update_ForceOutOfHours,
];
