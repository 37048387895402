export const EventLogGroup = {
  Admin: 'Admin',
  App: 'App',
} as const;

export type EventGroupKey = keyof typeof EventLogGroup;

export const EventLogGroups: EventGroupKey[] = Object.keys(
  EventLogGroup
) as EventGroupKey[];
