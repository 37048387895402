import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import UserProfileDTO from '../../../adapters/dto/user/UserProfileDTO';

const PREFIX = 'ProfileDetail';

const classes = {
  root: `${PREFIX}-root`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  line: `${PREFIX}-line`,
  info: `${PREFIX}-info`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.line}`]: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2),
  },

  [`& .${classes.info}`]: { width: '50%' }
}));

interface Props {
  userProfile: UserProfileDTO;
  edit(edit: boolean): void;
}

function ProfileDetail({ userProfile, edit }: Props) {


  const openEdit = () => {
    edit(true);
  };

  return (
    <StyledBox>
      <Box className={classes.line}>
        <Box className={classes.info}>
          <Typography variant="subtitle2">Username</Typography>
          <Typography variant="body2">{userProfile.username}</Typography>
        </Box>
        <Box className={classes.info}>
          <Typography variant="subtitle2">Display Name</Typography>
          <Typography variant="body2">{userProfile.displayname}</Typography>
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.info}>
          <Typography variant="subtitle2">Email</Typography>
          <Typography variant="body2">{userProfile.email}</Typography>
        </Box>
        <Box className={classes.info}>
          <Typography variant="subtitle2">Phone</Typography>
          <Typography variant="body2">{userProfile.phone}</Typography>
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <Button
          color="secondary"
          onClick={openEdit}
          variant="contained"
          className={classes.button}>
          edit
        </Button>
      </Box>
    </StyledBox>
  );
}

export default ProfileDetail;
