import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as AvatarOutline } from '../../assets/AvatarOutline.svg';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
  url: string | undefined;
  showOutline: boolean;
  large?: boolean;
}

interface Style {
  large?: boolean;
}

const PREFIX = 'Avatar';

const classes = {
  avatarContainer: `${PREFIX}-avatarContainer`,
  avatarContainerLarge: `${PREFIX}-avatarContainerLarge`,
  avatarOutlineImageWithoutShadow: `${PREFIX}-avatarOutlineImageWithoutShadow`,
  avatarOutlineImage: `${PREFIX}-avatarOutlineImage`,
  avatarImage: `${PREFIX}-avatarImage`,
  image: `${PREFIX}-image`,
  imageLarge: `${PREFIX}-imageLarge`,
  avatarOutline: `${PREFIX}-avatarOutline`,
  personIcon: `${PREFIX}-personIcon`,
  personIconLarge: `${PREFIX}-personIconLarge`,
  imageWidth: `${PREFIX}-imageWidth`,
};

const StyledBox = styled(Box)<Style>(({ theme, ...props }) => ({
  width: props.large ? '120px' : '40px',
  height: '40px',
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [`& .${classes.avatarContainerLarge}`]: {
    width: '130px',
    height: '130px',
  },

  [`& .${classes.avatarOutlineImageWithoutShadow}`]: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    height: 'auto',
    borderRadius: '7px',
    position: 'absolute',
  },

  [`& .${classes.avatarOutlineImage}`]: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    height: 'auto',
    borderRadius: '7px',
    position: 'absolute',
    // filter: 'drop-shadow(0px 0px 8px rgba(0, 240, 255, 0.7))',
  },

  [`& .${classes.avatarImage}`]: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    height: 'auto',
    borderRadius: '7px',
    position: 'absolute',
  },

  [`& .${classes.image}`]: {
    width: props.large ? '130px' : '38px',
    height: props.large ? '130px' : '38px',
    borderRadius: '10px',
  },

  [`& .${classes.avatarOutline}`]: {
    height: '100%',
    width: 'auto',
    zIndex: 1,
  },

  [`& .${classes.personIcon}`]: {
    color: '#C8B9E7',
    width: props.large ? '130px' : undefined,
    height: props.large ? '130px' : undefined,
  },

  [`& .${classes.imageWidth}`]: {
    width: '100%',
  },
}));

function Avatar({ url, showOutline, large }: Props) {
  return (
    <StyledBox large={large}>
      <Box
        className={
          showOutline
            ? !url
              ? classes.avatarOutlineImageWithoutShadow
              : classes.avatarOutlineImage
            : classes.avatarImage
        }>
        {url && url !== 'hs' && (
          <img src={url} alt="avatar" className={classes.image} />
        )}
        {!url && <PersonIcon fontSize="large" className={classes.personIcon} />}
      </Box>
      {showOutline && <AvatarOutline className={classes.avatarOutline} />}
    </StyledBox>
  );
}

export default Avatar;
