import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Role } from '../../constants/Roles';
import UserDTO from '../../adapters/dto/user/UserDTO';

const PREFIX = 'UserInfo';

const classes = {
  container: `${PREFIX}-container`,
  role: `${PREFIX}-role`,
  name: `${PREFIX}-name`,
  title: `${PREFIX}-title`,
  splitItems: `${PREFIX}-splitItems`,
  data: `${PREFIX}-data`,
  roleChip: `${PREFIX}-roleChip`,
  header: `${PREFIX}-header`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    paddingTop: '10px',
  },

  [`& .${classes.role}`]: { width: '45%', paddingLeft: '10px' },
  [`& .${classes.name}`]: { width: '45%' },

  [`& .${classes.title}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },

  [`& .${classes.splitItems}`]: {
    textAlign: 'left',
    width: '50%',
  },

  [`& .${classes.data}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
  },

  [`& .${classes.roleChip}`]: { margin: '5px' },
  [`& .${classes.header}`]: { paddingBottom: '20px' }
}));

interface Props {
  users: UserDTO[];
}

function UserInfo({ users }: Props) {


  return (
    <StyledBox className={classes.container}>
      <Typography variant="h5" className={classes.header}>
        Details
      </Typography>
      <Box className={classes.title}>
        <Typography variant="subtitle2" className={classes.splitItems}>
          Username
        </Typography>
        <Typography variant="subtitle2" className={classes.splitItems}>
          Patchwork ID
        </Typography>
      </Box>
      <Box className={classes.data}>
        {users && users.length === 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            {users[0].username}
          </Typography>
        )}
        {users && users.length === 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            {users[0].patchworkId}
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
      </Box>
      <Box className={classes.title}>
        <Typography variant="subtitle2" className={classes.splitItems}>
          First name
        </Typography>
        <Typography variant="subtitle2" className={classes.splitItems}>
          Last name
        </Typography>
      </Box>
      <Box className={classes.data}>
        {users && users.length === 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            {users[0].firstName}
          </Typography>
        )}
        {users && users.length === 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            {users[0].lastName}
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
      </Box>
      <Box className={classes.title}>
        <Typography variant="subtitle2">Email</Typography>
        <Typography variant="subtitle2" className={classes.splitItems}>
          Display Name
        </Typography>
      </Box>
      <Box className={classes.data}>
        {users && users.length === 1 && (
          <Typography variant="body2">{users[0].emailAddress}</Typography>
        )}
        {users && users.length === 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            {users[0].displayName}
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2" className={classes.splitItems}>
            multiple
          </Typography>
        )}
      </Box>
      <Box className={classes.title}>
        <Typography variant="subtitle2">Phone</Typography>
      </Box>
      <Box className={classes.data}>
        {users && users.length === 1 && (
          <Typography variant="body2">{users[0].phone}</Typography>
        )}
        {users && users.length > 1 && (
          <Typography variant="body2">multiple</Typography>
        )}
      </Box>
      <Box className={classes.title}>
        <Typography variant="subtitle2">Roles</Typography>
      </Box>
      <Box>
        {users &&
          users.length === 1 &&
          users[0].roles.map((r, index) => {
            const role = r as Role;
            return (
              <Chip
                label={role}
                key={index.toString()}
                className={classes.roleChip}
              />
            );
          })}
        {users && users.length > 1 && (
          <Typography variant="body2">multiple</Typography>
        )}
      </Box>
      {users && users.length === 1 && users[0].activationCode && (
        <Box>
          <Box className={classes.title}>
            <Typography variant="subtitle2">Activation Code</Typography>
          </Box>
          <Box className={classes.data}>
            <Typography variant="body2">{users[0].activationCode}</Typography>
          </Box>
        </Box>
      )}
    </StyledBox>
  );
}

export default UserInfo;
