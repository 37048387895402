import { useCallback } from 'react';
import useAuthStore from '../stores/useAuthStore';

export const useAuth = () => {
  const { loggedInUser, setLoggedInUser } = useAuthStore();

  const hasRole = useCallback(
    (role: string) =>
      loggedInUser && loggedInUser.roles && loggedInUser.roles.includes(role),
    [loggedInUser]
  );

  const hasAnyRole = useCallback(
    (roles: string[]) =>
      loggedInUser &&
      loggedInUser.roles &&
      loggedInUser.roles.some((r: string) => roles.includes(r)),
    [loggedInUser]
  );

  const setAccessToken = useCallback((token: string | undefined) => {
    if (token) {
      localStorage.setItem('access_token', token);
    } else {
      localStorage.removeItem('access_token');
    }
  }, []);

  return {
    loggedInUser,
    setLoggedInUser,
    hasRole,
    hasAnyRole,
    setAccessToken,
  };
};
