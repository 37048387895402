import useApi, { ApiOptions } from '../base/useApi';
interface Params {
  id: string;
}

interface Result {
  verified: boolean;
}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/user/verify',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useSaveUserVerify = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useSaveUserVerifyLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useSaveUserVerify;
