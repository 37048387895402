import './index.css';
import App from './App';
import React from 'react';
import '@fontsource/roboto';
import Providers from './Providers';
import { initSentry } from './SentryHelper';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

initSentry();

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

reportWebVitals();
