import useApi, { ApiOptions } from './base/useApi';

export interface ResetPasswordParams {
  token: string;
  password: string;
}

export interface ResetPasswordResult {}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/reset-password',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useResetPasswordLazy = (params?: ResetPasswordParams) => {
  return useApi<ResetPasswordParams, ResetPasswordResult>(options, params);
};

export default useResetPasswordLazy;
