import { IconButton, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

const PREFIX = 'SearchBox';

const classes = {
  searchName: `${PREFIX}-searchName`
};

const StyledInputAdornment = styled(InputAdornment)((
  {
    theme
  }
) => ({
  [`& .${classes.searchName}`]: {
    display: 'flex',
    align: 'center',
    width: '100%',
  }
}));

interface Props {
  title: string;
  search(value: string): void;
}

function SearchBox({ title, search }: Props) {

  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const searchPressed = () => {
    search(value);
  };

  return (
    <TextField
      id="name"
      label={title}
      autoComplete="off"
      onChange={handleChange}
      className={classes.searchName}
      fullWidth={true}
      InputProps={{
        endAdornment: (
          <StyledInputAdornment position="end">
            <IconButton aria-label="search" onClick={() => searchPressed()} size="large">
              <SearchIcon color="secondary" />
            </IconButton>
          </StyledInputAdornment>
        ),
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          searchPressed();
        }
      }}
    />
  );
}

export default SearchBox;
