import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import UserRegisterExperienceDTO from '@src/adapters/dto/user/UserRegisterExperienceDTO';
import { useSnackbar } from 'notistack';
interface Props {
  onContinue(experience: UserRegisterExperienceDTO): void;
  onBack(experience: UserRegisterExperienceDTO): void;
  experience: UserRegisterExperienceDTO | undefined;
}

const PREFIX = 'register-department';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.button}`]: {
    width: '30%',
    margin: theme.spacing(1),
  },
}));
function Experience({ experience, onBack, onContinue }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [numbersOfReferrers, setNumbersOfReferrers] = useState(5);
  const [numbersOfEndorsers, setNumbersOfEndorsers] = useState(5);
  const [yearsOfExperience, setYearsOfExperience] = useState<string>('');
  const [endorsers, setEndorsers] = useState<
    { index: number; value: string }[]
  >([]);
  const [referrers, setReferrers] = useState<
    { index: number; value: string }[]
  >([]);

  useEffect(() => {
    if (experience) {
      if (experience.yearsOfExperience) {
        setYearsOfExperience(experience.yearsOfExperience.toString());
      }

      if (experience && experience.endorsers) {
        setEndorsers(
          experience.endorsers.map((e, index) => {
            return { index: index, value: e };
          })
        );
      }

      if (experience && experience.referrers) {
        setReferrers(
          experience.referrers.map((r, index) => {
            return { index: index, value: r };
          })
        );
      }
    }
  }, [experience]);

  const handleReferrers = (index: number, value: string) => {
    setReferrers((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.index === index);

      if (itemIndex >= 0) {
        const updatedItems = [...currentItems];
        updatedItems[itemIndex].value = value;
        return updatedItems;
      } else {
        return [...currentItems, { index, value }];
      }
    });
  };

  const handleEndorsers = (index: number, value: string) => {
    setEndorsers((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.index === index);

      if (itemIndex >= 0) {
        const updatedItems = [...currentItems];
        updatedItems[itemIndex].value = value;
        return updatedItems;
      } else {
        return [...currentItems, { index, value }];
      }
    });
  };

  const handleYearsOfExperience = (years: string) => {
    if (!years) {
      setYearsOfExperience(years);
    } else {
      const num = parseInt(years);
      if (isNaN(num)) {
        enqueueSnackbar(`Years of experience must be a number`, {
          variant: 'error',
        });
      } else {
        setYearsOfExperience(years);
      }
    }
  };

  useEffect(() => {
    const num = parseInt(yearsOfExperience);
    if (yearsOfExperience != null && num >= 5) {
      setNumbersOfReferrers(2);
      setNumbersOfEndorsers(1);
    } else {
      setNumbersOfReferrers(4);
      setNumbersOfEndorsers(2);
    }
  }, [yearsOfExperience]);

  const handleContinue = () => {
    let errorRaised = false;
    const yearsAsNumber = parseInt(yearsOfExperience);
    if (isNaN(yearsAsNumber)) {
      enqueueSnackbar('Years of experience must be included', {
        variant: 'error',
      });
      errorRaised = true;
    }

    if (referrers.length < numbersOfReferrers) {
      enqueueSnackbar(`${numbersOfReferrers} Referrers must be included`, {
        variant: 'error',
      });
      errorRaised = true;
    }

    if (endorsers.length < numbersOfEndorsers) {
      enqueueSnackbar(
        `${numbersOfEndorsers > 1 ? numbersOfEndorsers : 'An'} Endorser${
          numbersOfEndorsers > 1 ? 's' : ''
        } must be included`,
        {
          variant: 'error',
        }
      );
      errorRaised = true;
    }

    if (!errorRaised && yearsAsNumber) {
      const experience: UserRegisterExperienceDTO = {
        endorsers: endorsers.map((e) => e.value),
        referrers: referrers.map((r) => r.value),
        yearsOfExperience: yearsAsNumber,
      };

      onContinue(experience);
    }
  };

  const handleBack = () => {
    const yearsAsNumber = parseInt(yearsOfExperience);

    const experience: UserRegisterExperienceDTO = {
      endorsers: endorsers.map((e) => e.value),
      referrers: referrers.map((r) => r.value),
      yearsOfExperience: yearsAsNumber ? yearsAsNumber : undefined,
    };

    onBack(experience);
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.subtitle}>
        Experience
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        You will need to provide referrers and endorsers based on your years of
        experience.
      </Typography>
      <TextField
        label="Years experience"
        autoComplete="off"
        fullWidth={true}
        type="number"
        onChange={(e) => handleYearsOfExperience(e.target.value)}
        value={yearsOfExperience}
      />
      <Typography variant="h6" className={classes.subtitle}>
        Referrers
      </Typography>
      {Array.from({ length: numbersOfReferrers }, (_, index) => (
        <TextField
          label="Referrer name"
          autoComplete="off"
          fullWidth={true}
          key={`referrer${index}`}
          onChange={(e) => handleReferrers(index, e.target.value)}
          value={
            referrers.find((e) => e.index === index)
              ? referrers.find((e) => e.index === index)?.value
              : ''
          }
        />
      ))}
      <Typography variant="h6" className={classes.subtitle}>
        Endorsers
      </Typography>
      {Array.from({ length: numbersOfEndorsers }, (_, index) => (
        <TextField
          label="Endorser name"
          autoComplete="off"
          fullWidth={true}
          key={`endorser${index}`}
          onChange={(e) => handleEndorsers(index, e.target.value)}
          value={
            endorsers.find((e) => e.index === index)
              ? endorsers.find((e) => e.index === index)?.value
              : ''
          }
        />
      ))}
      <Box className={classes.buttons}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleBack}
          className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleContinue}
          className={classes.button}>
          Continue
        </Button>
      </Box>
    </MainContainer>
  );
}

export default Experience;
