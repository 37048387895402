import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { EventLogTypes } from '../../constants/EventLogType';

interface Props {
  selection(name: string | null): void;
  selected: string | null;
  group: string | null;
}

function EventLogNameAutoComplete({ selection, selected, group }: Props) {
  const [open, setOpen] = useState(false);
  const [availableNames, setAvailableNames] = useState<string[]>([]);
  const loading = open;

  useEffect(() => {
    if (group) {
      setAvailableNames(EventLogTypes.filter((eln) => eln.startsWith(group)));
    } else {
      setAvailableNames([]);
    }
  }, [group]);

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Autocomplete
        open={open}
        onOpen={onOpen}
        disabled={!group}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(option, value) => {
          selection(value);
        }}
        value={selected}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option.split('_').slice(1).join(' ')}
        options={availableNames}
        loading={loading}
        filterSelectedOptions={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Event Name"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderOption={(props, option) => <>{option}</>}
        // renderOption={(option) => (
        //   <React.Fragment>
        //     <span>{option}</span>
        //   </React.Fragment>
        // )}
      />
    </Box>
  );
}
export default EventLogNameAutoComplete;
