import { Box, CardHeader, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/system';

interface Props {
  checkChanged(
    role: { value: string; checked: boolean },
    event: React.ChangeEvent<HTMLInputElement>
  ): void;
  role: { value: string; checked: boolean };
}

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background:
    'linear-gradient(0deg, #0B1928, #0B1928), linear-gradient(90deg, #111114 0%, #161516 100%)',
  width: '358px',
  height: '74px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  borderRadius: '16px',
  marginBottom: theme.spacing(2),
}));

function Role({ role, checkChanged }: Props) {
  return (
    <MainContainer key={role.value}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Box
          sx={{
            paddingRight: '10px',
          }}>
          <CardHeader
            variant="rounder"
            sx={{
              background: 'rgba(123, 199, 231, 0.08)',
              width: '40px',
              height: '40px',
            }}></CardHeader>
        </Box>
        <Typography variant="body1">{role.value}</Typography>
      </Box>
      <Checkbox
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          checkChanged(role, event)
        }
        checked={role.checked}></Checkbox>
    </MainContainer>
  );
}

export default Role;
