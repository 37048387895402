import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Typography } from '@mui/material';
import Dropzone from 'react-dropzone';
import { useGetAvatarUploadUrlLazy } from '../../../adapters/hooks/user/useGetAvatarUploadUrl';
import { useSaveUserAvatarImageNameLazy } from '../../../adapters/hooks/user/useSaveUserAvatarImageName';
import Avatar from '../../../components/user/Avatar';

const PREFIX = 'ProfileAvatar';

const classes = {
  details: `${PREFIX}-details`,
  entry: `${PREFIX}-entry`,
  dropzoneContainer: `${PREFIX}-dropzoneContainer`,
  image: `${PREFIX}-image`,
  avatar: `${PREFIX}-avatar`,
  dropzone: `${PREFIX}-dropzone`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.details}`]: {
    display: 'flex',
  },

  [`& .${classes.entry}`]: {
    width: '100%',
  },

  [`& .${classes.dropzoneContainer}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    border: '1px dashed #ACACAC',
    boxSizing: 'border-box',
    borderRadius: '20px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '250px',
    justifyContent: 'center',
  },

  [`& .${classes.image}`]: {
    borderRadius: '20px',
  },

  [`& .${classes.avatar}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dropzone}`]: {
    height: '75px',
    width: '350px',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  close(updated: boolean): void;
  open: boolean;
  url: string | undefined;
}

function ProfileAvatar({ url, close, open }: Props) {
  const { execute: getUploadURL } = useGetAvatarUploadUrlLazy();
  const { execute: saveAvatarImageName } = useSaveUserAvatarImageNameLazy();
  const [addedAvatar, setAddedAvatar] = useState<File>();
  const [avatarUrl, setAvatarUrl] = useState<string>();

  useEffect(() => {
    setAvatarUrl(url);
  }, [open, url]);

  useEffect(() => {
    if (addedAvatar) {
      setAvatarUrl(URL.createObjectURL(addedAvatar));
    }
  }, [addedAvatar]);

  const handleSave = async () => {
    if (addedAvatar) {
      const fileType = addedAvatar.type;

      const result = await getUploadURL({ imageType: fileType });
      const data = new FormData();
      Object.entries(result.avatar.fields).forEach(([field, value]) => {
        data.append(field, value);
      });

      data.append('file', addedAvatar, addedAvatar.name);

      await fetch(result.avatar.url, {
        method: 'POST',
        body: data,
      });

      await saveAvatarImageName({ imageName: result.imageName });

      setAddedAvatar(undefined);
      close(true);
    }
  };

  const handleCancel = () => {
    setAddedAvatar(undefined);
    close(false);
  };

  return (
    <StyledDialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Upload a new avatar</DialogTitle>
      <DialogContent>
        <Box className={classes.dropzoneContainer}>
          <Dropzone
            onDrop={(acceptedFiles) => {
              if (acceptedFiles.length === 1) {
                setAddedAvatar(acceptedFiles[0]);
              }
            }}>
            {({ getRootProps, getInputProps }) => (
              <div className={classes.dropzone} {...getRootProps()}>
                <div>
                  <input {...getInputProps()} />
                  <Box className={classes.avatar}>
                    <Avatar url={avatarUrl} showOutline={true} large={true} />
                  </Box>
                  <Typography variant="h5">Drag new avatar here</Typography>
                </div>
              </div>
            )}
          </Dropzone>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleSave()}
          color="secondary"
          variant="contained">
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ProfileAvatar;
