import {
  Box,
  Button,
  alpha,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import UserDTO from '../../adapters/dto/user/UserDTO';
import { useFindUsersLazy } from '../../adapters/hooks/user/useFindUser';
import { useSaveUserActiveLazy } from '../../adapters/hooks/user/useSaveUserActive';
import Layout from '../../components/layout/Layout';
import UserAdd from './UserAdd';
import UserEdit from './UserEdit';
import UserFilter from './UserFilter';
import UserInfo from './UserInfo';
import UserList from './UserList';

const PREFIX = 'UserManagement';

const classes = {
  pageTitle: `${PREFIX}-pageTitle`,
  information: `${PREFIX}-information`,
  tabSelection: `${PREFIX}-tabSelection`,
  userList: `${PREFIX}-userList`,
  filterBox: `${PREFIX}-filterBox`,
  details: `${PREFIX}-details`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  inActive: `${PREFIX}-inActive`,
  active: `${PREFIX}-active`,
};

const StyledLayout = styled(Layout)(({ theme }) => ({
  [`& .${classes.pageTitle}`]: {
    padding: '0 20px 20px 20px',
    display: 'flex',
  },

  [`& .${classes.information}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  [`& .${classes.tabSelection}`]: {
    paddingLeft: '44px',
  },

  [`& .${classes.userList}`]: {
    height: '80vh',
    width: '60%',
    overflow: 'auto',
  },

  [`& .${classes.filterBox}`]: { paddingBottom: '20px' },
  [`& .${classes.details}`]: { width: '38%', paddingLeft: '20px' },
  [`& .${classes.buttons}`]: { paddingBottom: '20px' },
  [`& .${classes.button}`]: { marginRight: '10px' },

  [`& .${classes.inActive}`]: {
    backgroundColor: alpha(theme.palette.error.main, 0.9),
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },

  [`& .${classes.active}`]: {
    backgroundColor: alpha(theme.palette.success.main, 0.9),
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}));

function UserManagement() {
  const { execute: userFind } = useFindUsersLazy();
  const { execute: userActive } = useSaveUserActiveLazy();
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [role, setRole] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState<UserDTO[]>([]);
  const [editUser, setEditUser] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const selectTab = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const findUser = useCallback(() => {
    userFind({
      pageSize: rowsPerPage,
      currentPage: currentPage,
      role: role === null ? '' : role,
      searchText: searchText === null ? '' : searchText.trim(),
      disabled: disabled,
      removeCurrentUser: false,
    }).then((result) => {
      setUsers(result.users);
      if (result.recordCount) {
        setRecordCount(result.recordCount);
      }
      setSelectedUsers([]);
    });
  }, [userFind, role, searchText, rowsPerPage, currentPage, disabled]);

  useEffect(() => {
    findUser();
  }, [findUser]);

  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentPage(0);
    if (newValue === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setSelectedTab(newValue);
    setEditUser(false);
  };

  const activate = async (active: Boolean) => {
    let userIds: string[] = [];

    for (let index = 0; index < selectedUsers.length; index++) {
      const user = selectedUsers[index];
      if (user.id != null) {
        userIds.push(user.id);
      }
    }

    await userActive({
      userIds,
      active,
    });

    findUser();
  };

  const updateSelectedUsers = (users: UserDTO[]) => {
    setSelectedUsers(users);
    setEditUser(false);
    setAddUser(false);
  };

  const userSaved = (user: UserDTO) => {
    let workingUsers = [...users];
    if (editUser) {
      let existingUser = workingUsers.find((u) => u.id === user.id);
      if (existingUser) {
        const newArray = workingUsers.map((u) => (u.id === user.id ? user : u));
        setSelectedUsers([existingUser]);
        setUsers(newArray);
      }
      setEditUser(false);
    }

    if (addUser) {
      setSelectedUsers([user]);
      workingUsers.push(user);
      setUsers(workingUsers);
      setAddUser(false);
    }
  };

  const showAddUser = () => {
    setSelectedUsers([]);
    setEditUser(false);
    setAddUser(true);
  };

  const cancelEdit = () => {
    setEditUser(false);
  };

  const cancelAdd = () => {
    setAddUser(false);
  };

  const userUpdated = (dirty: Boolean) => {};

  const search = (value: string) => {
    setSearchText(value);
  };

  return (
    <StyledLayout>
      <Box className={classes.pageTitle}>
        <Typography variant="h4">User management</Typography>
        <Tabs
          value={selectedTab}
          onChange={tabChange}
          className={classes.tabSelection}>
          <Tab label="Active" {...selectTab(0)} />
          <Tab label="Inactive" {...selectTab(1)} />
        </Tabs>
      </Box>
      <Box className={classes.information}>
        <Paper elevation={8} className={classes.userList}>
          <Box className={classes.filterBox}>
            <UserFilter
              setRole={setRole}
              role={role}
              search={search}
              add={showAddUser}
            />
          </Box>
          <UserList
            users={users}
            selectedPage={currentPage}
            rowsPerPage={rowsPerPage}
            recordCount={recordCount}
            selectedUsers={selectedUsers}
            changePage={setCurrentPage}
            changeRowsPerPage={setRowsPerPage}
            setSelectedUsers={updateSelectedUsers}
          />
        </Paper>
        <Box className={classes.details}>
          <Box className={classes.buttons}>
            {selectedUsers && selectedUsers.length === 1 && !editUser && (
              <Button
                onClick={() => setEditUser(true)}
                color="secondary"
                variant="outlined"
                className={classes.button}>
                Edit
              </Button>
            )}
            {selectedUsers &&
              selectedUsers.length > 0 &&
              !editUser &&
              !disabled && (
                <Button
                  color="primary"
                  onClick={() => activate(false)}
                  variant="contained"
                  className={`${classes.button} ${classes.inActive}`}>
                  Set Inactive
                </Button>
              )}
            {selectedUsers &&
              selectedUsers.length > 0 &&
              !editUser &&
              disabled && (
                <Button
                  color="primary"
                  onClick={() => activate(true)}
                  variant="contained"
                  className={`${classes.button} ${classes.active}`}>
                  Set Active
                </Button>
              )}
          </Box>
          {selectedUsers && selectedUsers.length > 0 && !editUser && (
            <UserInfo users={selectedUsers} />
          )}
          {selectedUsers && selectedUsers.length === 1 && editUser && (
            <UserEdit
              user={selectedUsers[0]}
              saved={userSaved}
              updated={userUpdated}
              cancel={cancelEdit}
            />
          )}
          {addUser && (
            <UserAdd
              saved={userSaved}
              updated={userUpdated}
              cancel={cancelAdd}
            />
          )}
        </Box>
      </Box>
    </StyledLayout>
  );
}

export default UserManagement;
