import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import UserProfileDTO from '../../../adapters/dto/user/UserProfileDTO';
import { useSaveUserProfileHubLazy } from '../../../adapters/hooks/user/useSaveUserProfileHub';

const PREFIX = 'ProfileEdit';

const classes = {
  root: `${PREFIX}-root`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  line: `${PREFIX}-line`,
  info: `${PREFIX}-info`,
};

const Root = styled('form')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.line}`]: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(2),
  },

  [`& .${classes.info}`]: { width: '50%', padding: theme.spacing(1) },
}));

interface Props {
  profile: UserProfileDTO;
  cancel(): void;
  saved(profile: UserProfileDTO): void;
}

interface ProfileFormData {
  firstname: string;
  lastname: string;
  displayname: string;
  email: string;
  phone: string;
}

function ProfileEdit({ profile, cancel, saved }: Props) {
  const { handleSubmit, register, setValue } = useForm<ProfileFormData>({
    mode: 'all',
  });

  const { execute } = useSaveUserProfileHubLazy();

  useEffect(() => {
    setValue('firstname', profile.firstname);
    setValue('lastname', profile.lastname);
    setValue('displayname', profile.displayname);
    setValue('email', profile.email);
    setValue('phone', profile.phone);
  }, [setValue, profile]);

  const onSubmit = async (data: ProfileFormData) => {
    const userProfile = profile;
    userProfile.lastname = data.lastname;
    userProfile.firstname = data.firstname;
    userProfile.displayname = data.displayname;
    userProfile.email = data.email;
    userProfile.phone = data.phone;
    const result = await execute({ userProfile });
    saved(result.userProfile);
  };

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Typography variant="body2">username</Typography>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.info}>
          <TextField
            {...register('firstname', {
              required: true,
            })}
            required
            label="First Name"
            name="firstname"
            size="medium"
            fullWidth
            autoComplete="false"
          />
        </Box>
        <Box className={classes.info}>
          <TextField
            {...register('lastname', {
              required: 'You must specify a last name',
            })}
            required
            label="Last Name"
            name="lastname"
            size="medium"
            fullWidth
            autoComplete="false"
          />
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.info}>
          <TextField
            {...register('email', {
              required: 'You must specify an email',
            })}
            required
            label="Email"
            name="email"
            size="medium"
            fullWidth
            autoComplete="false"
          />
        </Box>
        <Box className={classes.info}>
          <TextField
            {...register('displayname', {
              required: true,
            })}
            required
            label="Display Name"
            name="displayname"
            size="medium"
            fullWidth
            autoComplete="false"
          />
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.info}>
          <TextField
            {...register('phone', {
              required: 'You must specify a phone number',
            })}
            required
            label="Phone"
            name="phone"
            size="medium"
            fullWidth
            autoComplete="false"
          />
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <Button
          color="primary"
          type="submit"
          variant="outlined"
          className={classes.button}
          onClick={cancel}>
          cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          variant="contained"
          className={classes.button}>
          save
        </Button>
      </Box>
    </Root>
  );
}

export default ProfileEdit;
