import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import EventLogDTO from '../../adapters/dto/eventLog/EventLogDTO';
import Layout from '../../components/layout/Layout';
import Details from './components/Details';

import SearchBody from './components/SearchBody';

const PREFIX = 'AuditLog';

const classes = {
  pageHeader: `${PREFIX}-pageHeader`,
  pageTitle: `${PREFIX}-pageTitle`,
  container: `${PREFIX}-container`,
  searchBody: `${PREFIX}-searchBody`,
  details: `${PREFIX}-details`,
};

const StyledLayout = styled(Layout)(({ theme }) => ({
  [`& .${classes.pageHeader}`]: {
    padding: '0 20px 20px 20px',
    display: 'flex',
  },

  [`& .${classes.pageTitle}`]: {
    paddingRight: '40px',
  },

  [`& .${classes.container}`]: {
    display: 'flex',
  },

  [`& .${classes.searchBody}`]: {
    width: '70%',
  },

  [`& .${classes.details}`]: {
    width: '30%',
    paddingLeft: theme.spacing(4),
  },
}));

function AuditLog() {
  const [selected, setSelected] = useState<EventLogDTO>();

  return (
    <StyledLayout>
      <Box className={classes.pageHeader}>
        <Box className={classes.pageTitle}>
          <Typography variant="h4">Audit Log</Typography>
        </Box>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.searchBody}>
          <SearchBody selected={selected} setSelected={setSelected} />
        </Box>
        <Box className={classes.details}>
          <Details eventLog={selected} />
        </Box>
      </Box>
    </StyledLayout>
  );
}

export default AuditLog;
