import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
export function initSentry() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    debug: process.env.SENTRY_ENVIRONMENT === 'dev',
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

export function logError(e: any) {
  Sentry.captureException(e);
}

export function setUser(username: string | undefined) {
  if (username) {
    Sentry.setUser({ username });
  } else {
    Sentry.configureScope((scope) => scope.setUser(null));
  }
}
