import UserDTO from '../../dto/user/UserDTO';
import useApi, { ApiOptions } from '../base/useApi';

interface Params {
  pageSize: number;
  currentPage: number;
  role: string | undefined;
  searchText: string;
  disabled: boolean;
  removeCurrentUser: boolean;
  removeUser?: string;
  showAllUsers?: boolean;
}

interface Result {
  users: UserDTO[];
  recordCount: number;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/users',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useFindUsers = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useFindUsersLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useFindUsers;
