import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import DepartmentDTO from '@src/adapters/dto/department/DepartmentDTO';
import { useFindDepartmentsLazy } from '@src/adapters/hooks/department/useFindDepartments';
import { useCallback, useEffect, useState } from 'react';
import Department from './Department';
import { useSnackbar } from 'notistack';

interface Props {
  selectedDepartments: DepartmentDTO[];
  onContinue(departments: DepartmentDTO[]): void;
  onBack(departments: DepartmentDTO[]): void;
}

const PREFIX = 'register-department';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.button}`]: {
    width: '30%',
    margin: theme.spacing(1),
  },
}));

interface SelectableDepartmentDTO extends DepartmentDTO {
  isSelected: boolean;
}

function DepartmentList({ onContinue, onBack, selectedDepartments }: Props) {
  const { execute: find } = useFindDepartmentsLazy();
  const [departments, setDepartments] = useState<SelectableDepartmentDTO[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const loadDepartments = useCallback(() => {
    find().then((result) => {
      const selectableDepartments = result.departments.map((department) => ({
        ...department,
        isSelected: selectedDepartments.find((sd) => sd.id === department.id)
          ? true
          : false,
      }));
      setDepartments(selectableDepartments);
    });
  }, [find, selectedDepartments]);

  useEffect(() => {
    loadDepartments();
  }, [loadDepartments]);

  const handleCheckChanged = (departmentId: string, checked: boolean) => {
    setDepartments((prevDepartments) => {
      return prevDepartments.map((d) =>
        d.id === departmentId ? { ...d, isSelected: checked } : d
      );
    });
  };

  const handleContinue = () => {
    const selectedDepartments = departments
      .filter((department) => department.isSelected)
      .map(({ id, name, isActive, roles }) => ({ id, name, isActive, roles }));

    if (selectedDepartments.length < 1) {
      enqueueSnackbar(`At least one department must be selected`, {
        variant: 'error',
      });
    } else {
      onContinue(selectedDepartments);
    }
  };

  const handleBack = () => {
    const selectedDepartments = departments
      .filter((department) => department.isSelected)
      .map(({ id, name, isActive, roles }) => ({ id, name, isActive, roles }));
    onBack(selectedDepartments);
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.subtitle}>
        What department/s do you usually work in?
      </Typography>
      {departments &&
        departments.map((d) => {
          return (
            <Department
              checkChanged={handleCheckChanged}
              department={d}
              checked={d.isSelected}
            />
          );
        })}
      <Box className={classes.buttons}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleBack}
          className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleContinue}
          className={classes.button}>
          Continue
        </Button>
      </Box>
    </MainContainer>
  );
}

export default DepartmentList;
