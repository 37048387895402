import useApi, { ApiOptions } from '../base/useApi';
import UserCrewMemberDTO from '@src/adapters/dto/user/UserCrewMemberDTO';

interface Params {
  id: string;
}

interface Result {
  member: UserCrewMemberDTO;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/crew_member/',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useGetCrewMember = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useGetCrewMemberLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useGetCrewMember;
