import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useAuth } from '../auth/hooks/useAuth';
import { useEffect } from 'react';
import useCurrentUserDetailsLazy from '../adapters/hooks/useCurrentUserDetails';

const RequireAuth = () => {
  const location = useLocation();
  const { execute: getCurrentUser } = useCurrentUserDetailsLazy();
  const { loggedInUser, setLoggedInUser } = useAuth();

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await getCurrentUser();
        setLoggedInUser(user);
      } catch (e) {
        setLoggedInUser(null);
      }
    };

    if (loggedInUser === undefined) {
      getUser();
    }
  }, [loggedInUser, getCurrentUser, setLoggedInUser]);

  //   const isAllowed = loggedInUser && (!roles || hasAnyRole(roles));
  const isAllowed = loggedInUser;

  if (!isAllowed) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
};

export default RequireAuth;
