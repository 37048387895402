import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { EventLogGroups } from '../../constants/EventLogGroup';

interface Props {
  selection(selectedGroup: string | null): void;
  selected: string | null;
}

function EventLogGroupAutoComplete({ selection, selected }: Props) {
  const [open, setOpen] = useState(false);
  const [availableGroups] = useState<string[]>(EventLogGroups);
  const loading = open;

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <Box>
      <Autocomplete
        open={open}
        onOpen={onOpen}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(option, value) => {
          selection(value);
        }}
        value={selected}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        options={availableGroups}
        loading={loading}
        filterSelectedOptions={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Event Group"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
export default EventLogGroupAutoComplete;
