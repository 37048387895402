import useApi, { ApiOptions } from '../base/useApi';
import DepartmentDTO from '@src/adapters/dto/department/DepartmentDTO';

interface Params {}

interface Result {
  departments: DepartmentDTO[];
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/departments/find/',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useFindDepartments = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useFindDepartmentsLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useFindDepartments;
