import UserRegisterDTO from '@src/adapters/dto/user/UserRegisterDTO';
import useApi, { ApiOptions } from '../base/useApi';
interface Params {
  user: UserRegisterDTO;
}

interface Result {
  checked: boolean;
}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/user/register_validate',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useRegisterUserValidate = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useRegisterUserValidateLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useRegisterUserValidate;
