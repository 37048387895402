import useApi, { ApiOptions } from './base/useApi';

export interface CurrentUserDetailsParams {}

export interface CurrentUserDetailsResult {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/current-user-details',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useCurrentUserDetailsLazy = (
  params?: CurrentUserDetailsParams
) => {
  options.lazy = true;
  return useApi<CurrentUserDetailsParams, CurrentUserDetailsResult>(
    options,
    params
  );
};

export default useCurrentUserDetailsLazy;
