import UserCrewMemberHeaderDTO from '@src/adapters/dto/user/UserCrewMemberHeaderDTO';
import useApi, { ApiOptions } from '../base/useApi';

interface Params {
  pageSize: number;
  currentPage: number;
  unverifiedOnly: Boolean;
}

interface Result {
  members: UserCrewMemberHeaderDTO[];
  recordCount: number;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/crew_members/',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useFindCrewMembers = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useFindCrewMembersLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useFindCrewMembers;
