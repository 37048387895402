import EventLogDTO from '../../dto/eventLog/EventLogDTO';
import useApi, { ApiOptions } from '../base/useApi';

interface Params {
  currentPage: number;
  pageSize: number;
  from: Date | undefined;
  to: Date | undefined;
  hubUsername: string | undefined;
  cypUsername: string | undefined;
  group: string | undefined;
  name: string | undefined;
}

interface Result {
  eventLogs: EventLogDTO[];
  recordCount: number;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/eventlog',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useFindEventLog = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useFindEventLogLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useFindEventLog;
