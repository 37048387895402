import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { addDays } from 'date-fns';
import Slot from './components/Slot';
import useGetAvailabilityLazy from '@src/adapters/hooks/availability/useGetAvailabilities';
import UserAvailabilityDTO from '@src/adapters/dto/user/UserAvailabilityDTO';
import { useSnackbar } from 'notistack';

const PREFIX = 'availability';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.main,
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const StyledDates = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(3),

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

function Availability() {
  const location = useLocation();
  const dates = Array.from({ length: 14 }, (_, i) => addDays(new Date(), i));

  const { execute: getAvailabilites } = useGetAvailabilityLazy();
  const [availabilities, setAvailabilities] = useState<UserAvailabilityDTO[]>(
    []
  );
  const [key, setKey] = useState<string>('');

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const key = queryParams.get('key');
    if (!key) {
      enqueueSnackbar(
        'No key supplied please ensure you have used the link sent to you',
        {
          variant: 'error',
        }
      );
    } else {
      setKey(key);
    }
  }, [key, location.search, enqueueSnackbar]);

  const checkAvailable = useCallback(() => {
    if (key) {
      getAvailabilites({
        key: key,
      }).then((result) => {
        setAvailabilities(result.availabilities);
      });
    }
  }, [getAvailabilites, key]);

  useEffect(() => {
    checkAvailable();
  }, [checkAvailable]);

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.title}>
        Set availability
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Tap the status of a day to update availability.
      </Typography>
      <StyledDates>
        {key &&
          dates.map((date, index) => (
            <Box key={index}>
              <Slot
                date={date}
                availability={availabilities.find((a) => a.date === date)}
                key={key}
              />
            </Box>
          ))}
      </StyledDates>
      <Typography variant="h2" className={classes.subtitle}>
        Once finished, you can close your browser.
      </Typography>
    </MainContainer>
  );
}

export default Availability;
