import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Layout from '@src/components/layout/Layout';
import { useEffect, useState } from 'react';
import CrewList from './components/CrewList';

const PREFIX = 'Template';

const classes = {
  pageTitle: `${PREFIX}-pageTitle`,
  paper: `${PREFIX}-information`,
};

const StyledTitle = styled(Box)(({ theme }) => ({
  padding: '0 20px 20px 20px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
}));

function Crew() {
  const [unverifiedOnly, setUnverifiedOnly] = useState(false);

  useEffect(() => {}, []);

  const handleUnverifiedChange = () => {
    setUnverifiedOnly((current) => {
      return !current;
    });
  };

  return (
    <Layout>
      <StyledTitle>
        <Typography variant="h4">Crew</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={unverifiedOnly}
              onChange={handleUnverifiedChange}
            />
          }
          label="Show only unverified"
        />
      </StyledTitle>
      <Paper elevation={8} className={classes.paper}>
        <CrewList unverifiedOnly={unverifiedOnly} />
      </Paper>
    </Layout>
  );
}

export default Crew;
