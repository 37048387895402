import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import { Roles } from '../../constants/Roles';

interface Props {
  selection(roles: string[]): void;
  userRoles: string[];
  showAllRoles?: boolean;
}

function RolesAutoComplete({ selection, userRoles, showAllRoles }: Props) {
  const [open, setOpen] = useState(false);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const loading = open;

  useEffect(() => {
    if (userRoles) {
      setSelectedRoles(userRoles);
    }

    if (showAllRoles) {
      setAvailableRoles(Roles);
    } else {
      setAvailableRoles(Roles);
    }
  }, [userRoles, showAllRoles]);

  const onOpen = () => {
    setOpen(true);
  };

  const updateSelectedRoles = (values: string[]) => {
    setSelectedRoles(values);
    selection(values);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        open={open}
        disableClearable={true}
        value={selectedRoles}
        onOpen={onOpen}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(option, values) => {
          updateSelectedRoles(values);
        }}
        isOptionEqualToValue={(option, value) => option === value}
        getOptionLabel={(option) => option}
        options={[...selectedRoles, ...availableRoles]}
        loading={loading}
        filterSelectedOptions={true}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Roles"
            variant="standard"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
}
export default RolesAutoComplete;
