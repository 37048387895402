import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useRequestPasswordResetLazy from '../../adapters/hooks/useRequestPasswordReset';

const PREFIX = 'ForgottenPassword';

const classes = {
  container: `${PREFIX}-container`,
  forgottenPassword: `${PREFIX}-forgottenPassword`,
  logo: `${PREFIX}-logo`,
  instructions: `${PREFIX}-instructions`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(3),
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  [`& .${classes.forgottenPassword}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.logo}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.instructions}`]: {
    marginBottom: '20px',
  },
}));

interface ForgottenPasswordFormData {
  username: string;
}

function ForgottenPassword() {
  const navigate = useNavigate();
  const { execute } = useRequestPasswordResetLazy();
  const { handleSubmit, register, formState } =
    useForm<ForgottenPasswordFormData>({
      mode: 'all',
    });

  const onSubmit = handleSubmit(async (data) => {
    // save tokens
    await execute({ username: data.username });
    navigate('/login');
  });

  return (
    <StyledContainer className={classes.container} maxWidth="xs">
      <Paper>
        <Box m={5}>
          <Typography className={classes.instructions}>
            Type in your username and we will send you an email to reset your
            password
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('username', { required: true })}
                      required
                      label="Username"
                      name="username"
                      size="small"
                      variant="outlined"
                      autoComplete="username"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isValid}>
                  Reset password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </StyledContainer>
  );
}

export default ForgottenPassword;
