import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useFindUsersLazy } from '../../adapters/hooks/user/useFindUser';
import UserDTO from '../../adapters/dto/user/UserDTO';
import { Role } from '../../constants/Roles';

interface Props {
  selection(user: UserDTO | null): void;
  selectedUser: UserDTO | null;
  disabled?: boolean;
  removeCurrentUser?: boolean;
  id: string;
  role: Role | undefined;
  label?: string;
  showInativeUsers?: boolean;
  removeUser?: string;
}

function UserAutoComplete({
  selection,
  selectedUser,
  disabled,
  removeCurrentUser,
  id,
  role,
  label,
  removeUser,
  showInativeUsers,
}: Props) {
  const [open, setOpen] = useState(false);
  const { execute: findUsers } = useFindUsersLazy();
  const [options, setOptions] = useState<UserDTO[]>([]);
  const [searching, setSearching] = useState(false);
  const loading = open && options.length === 0 && searching;

  const onChangeHandle = async (value: string) => {
    searchUser(value);
  };

  const onOpen = () => {
    searchUser('');
    setOpen(true);
  };

  const searchUser = async (value: string) => {
    setSearching(true);

    const result = await findUsers({
      role: role,
      searchText: value,
      pageSize: 10000,
      currentPage: 0,
      disabled: showInativeUsers === true,
      removeCurrentUser: removeCurrentUser ? removeCurrentUser : false,
      showAllUsers: showInativeUsers === true,
      removeUser,
    });
    if (result.users !== undefined) {
      setOptions(result.users);
      setSearching(false);
    }
  };

  return (
    <Autocomplete
      id={id}
      open={open}
      disableClearable={false}
      disabled={disabled}
      value={selectedUser}
      onOpen={onOpen}
      fullWidth
      onClose={() => setOpen(false)}
      onChange={(option, value) => {
        if (value) {
          selection(value);
        } else {
          selection(null);
        }
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
      options={options}
      loading={loading}
      filterSelectedOptions={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label ? label : 'Available Users'}
          variant="standard"
          onChange={(ev) => {
            if (ev.target.value !== '' || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
export default UserAutoComplete;
