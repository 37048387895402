import { Box, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EventLogFilterDTO from '../../../adapters/dto/eventLog/EventLogFilterDTO';
import UserDTO from '../../../adapters/dto/user/UserDTO';
import EventLogGroupAutoComplete from '../../../components/eventLog/EventLogGroupAutoComplete';
import EventLogNameAutoComplete from '../../../components/eventLog/EventLogNameAutoComplete';
import UserAutoComplete from '../../../components/user/UserAutoComplete';
import dayjs from 'dayjs';

const PREFIX = 'Filter';

const classes = {
  container: `${PREFIX}-container`,
  filters: `${PREFIX}-filters`,
  filter: `${PREFIX}-filter`,
  status: `${PREFIX}-status`,
  row: `${PREFIX}-row`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },

  [`& .${classes.filters}`]: { width: '100%' },
  [`& .${classes.filter}`]: { width: '100%', paddingLeft: '20px' },
  [`& .${classes.status}`]: { width: '20%' },
  [`& .${classes.row}`]: { display: 'flex', alignItems: 'center' },
  [`& .${classes.buttons}`]: { display: 'flex', height: '32px' },
  [`& .${classes.button}`]: { marginLeft: '10px' },
}));

interface Props {
  search(filter: EventLogFilterDTO): void;
}

interface FilterFormData {
  from: Date;
  to: Date;
  username: string;
}

function Filter({ search }: Props) {
  const [hubUser, setHubuser] = useState<UserDTO | null>(null);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedName, setSelectedName] = useState<string | null>(null);
  useEffect(() => {
    if (!selectedGroup) {
      setSelectedName(null);
    }
  }, [selectedGroup]);

  const { handleSubmit, register, reset } = useForm<FilterFormData>({
    mode: 'all',
  });

  const updateSelectedGroup = (group: string) => {
    setSelectedName(null);
    setSelectedGroup(group);
  };

  const updateClosedTherapist = (user: UserDTO | null) => {
    setHubuser(user);
  };

  const onSubmit = handleSubmit(async (data) => {
    const filter: EventLogFilterDTO = {
      to: data.to
        ? dayjs(data.to).isValid()
          ? dayjs(data.to).toDate()
          : undefined
        : undefined,
      from: data.from
        ? dayjs(data.from).isValid()
          ? dayjs(data.from).toDate()
          : undefined
        : undefined,
      cypUsername: data.username ? data.username : undefined,
      group: selectedGroup ? selectedGroup : undefined,
      name: selectedName ? selectedName : undefined,
      hubUsername: hubUser ? hubUser.username : undefined,
    };
    search(filter);
  });

  const resetData = () => {
    reset({ from: undefined, to: undefined, username: '' });
    setSelectedGroup(null);
    setSelectedName(null);
    setHubuser(null);
  };

  return (
    <StyledBox className={classes.container}>
      <Box className={classes.filters}>
        <Box className={classes.row}>
          <Box className={classes.filter}>
            <TextField
              autoFocus
              margin="dense"
              name="from"
              label="From"
              type="datetime-local"
              {...register}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
          <Box className={classes.filter}>
            <TextField
              autoFocus
              margin="dense"
              name="to"
              label="To"
              type="datetime-local"
              {...register}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Box>
          <Box className={classes.filter}>
            <UserAutoComplete
              selection={updateClosedTherapist}
              selectedUser={hubUser}
              role={undefined}
              label="Hub User"
              removeCurrentUser={false}
              id="assignUser"
              showInativeUsers={true}
            />
          </Box>
          <Box className={classes.filter}>
            <TextField
              autoFocus
              margin="dense"
              name="username"
              label="CYP username"
              type="text"
              {...register}
              fullWidth
              autoComplete="off"
            />
          </Box>
        </Box>
        <Box className={classes.row}>
          <Box className={classes.filter}>
            <EventLogGroupAutoComplete
              selection={updateSelectedGroup}
              selected={selectedGroup}
            />
          </Box>
          <Box className={classes.filter}>
            <EventLogNameAutoComplete
              selection={setSelectedName}
              selected={selectedName}
              group={selectedGroup}
            />
          </Box>
          <Box className={classes.buttons}>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={onSubmit}>
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              size="small"
              onClick={resetData}>
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
}

export default Filter;
