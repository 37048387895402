import useApi, { ApiOptions } from '../base/useApi';
import UserAvailabilityDTO from '@src/adapters/dto/user/UserAvailabilityDTO';

export interface Params {
  key: string;
}

export interface Result {
  availabilities: UserAvailabilityDTO[];
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/availability/',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useGetAvailabilitiesLazy = (params?: Params) => {
  return useApi<Params, Result>(options, params);
};

export default useGetAvailabilitiesLazy;
