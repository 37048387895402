import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';

export function calculateAge(date: Date): number {
  if (!dayjs(date).isValid()) {
    return 0;
  }

  const today = new Date();
  const dateFrom = dayjs(date);
  let age = today.getFullYear() - dateFrom.year();
  var m = today.getMonth() - dateFrom.month();
  if (m < 0 || (m === 0 && today.getDate() < dateFrom.day())) {
    age--;
  }
  return age;
}

export function timeFrom(date: Date, withoutSuffix: boolean) {
  dayjs.extend(relativeTime);
  if (dayjs(date).isValid()) {
    return dayjs(date).fromNow(true);
  }

  return '';
}

export function timeTo(date: Date) {
  dayjs.extend(relativeTime);
  if (dayjs(date).isValid()) {
    return dayjs().to(dayjs(date));
  }

  return '';
}

export function formatDate(date: Date | string, format: string) {
  if (dayjs(date).isValid()) {
    return dayjs(date).format(format);
  }
}

export function createDate(date: string, format: string) {
  if (dayjs(date, format).isValid()) {
    return dayjs(date, format);
  }
}

export function isToday(date: Date) {
  if (
    dayjs(date).isValid() &&
    dayjs(date).day() === dayjs().day() &&
    dayjs(date).month() === dayjs().month() &&
    dayjs(date).year() === dayjs().year()
  ) {
    return true;
  }
  return false;
}

export function isBefore(firstDate: Date, secondDate: Date) {
  if (dayjs(firstDate).isValid() && dayjs(secondDate).isValid()) {
    return dayjs(firstDate).isBefore(dayjs(secondDate));
  }

  return false;
}

export function isAfterNow(date: Date) {
  if (dayjs(date).isValid()) {
    return dayjs(date).isAfter(dayjs());
  }

  return false;
}

export function isBeforeNow(date: Date | string) {
  if (dayjs(date).isValid()) {
    return dayjs(date).isBefore(dayjs());
  }

  return false;
}

export function inTheFuture(date: Date | string) {
  if (dayjs(date).isValid()) {
    return dayjs(date).isAfter(dayjs());
  }

  return false;
}

export function addMinutes(date: Date, mins: number) {
  if (dayjs(date).isValid()) {
    return dayjs(date).add(mins, 'm').toDate();
  }
}

export function returnIfValid(date: string) {
  if (dayjs(date).isValid()) {
    return dayjs(date).toDate();
  }
}

export function startOfWeek() {
  dayjs.extend(weekday);
  return dayjs().day(1).startOf('day');
}

export function endOfWeek() {
  dayjs.extend(weekday);
  return dayjs().day(1).add(6, 'day').endOf('day');
}

export function todayFormattedForMinMax() {
  return dayjs().format('YYYY-MM-DD');
}
