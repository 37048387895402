import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserDTO from '../../adapters/dto/user/UserDTO';
import RolesAutoComplete from '../../components/user/RolesAutoComplete';
import { useCreateUserLazy } from '../../adapters/hooks/user/useCreateUser';

const PREFIX = 'UserAdd';

const classes = {
  container: `${PREFIX}-container`,
  forgottenPassword: `${PREFIX}-forgottenPassword`,
  logo: `${PREFIX}-logo`,
  instructions: `${PREFIX}-instructions`,
  header: `${PREFIX}-header`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  [`& .${classes.forgottenPassword}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.logo}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.instructions}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.header}`]: { paddingBottom: '20px' },
}));

interface Props {
  saved(user: UserDTO): void;
  cancel(): void;
  updated(dirty: Boolean): void;
}

interface UserAddFormData {
  patchworkId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  tags: string[];
  displayName: string;
}

function UserAdd({ saved, cancel, updated }: Props) {
  const { execute: userCreate } = useCreateUserLazy();
  const [roles, setRoles] = useState<string[]>([]);
  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm<UserAddFormData>({
    mode: 'all',
  });

  // const password = useRef({});
  // password.current = watch('password', '');

  const onSubmit = handleSubmit(async (data) => {
    let user: UserDTO = {
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.email,
      roles,
      patchworkId: data.patchworkId,
      phone: data.phone,
      displayName: data.displayName,
    };

    const savedUser = await userCreate({ user });
    saved(savedUser.user);
  });

  return (
    <StyledContainer className={classes.container}>
      <Box>
        <Typography variant="h5" className={classes.header}>
          Invite new user
        </Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Patchwork Id (optional)"
                    {...register}
                    name="patchworkId"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('displayName', {
                      required: 'You must specify a first name',
                    })}
                    required
                    label="First Name"
                    name="firstName"
                    size="small"
                    variant="standard"
                  />
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('displayName', {
                      required: 'You must specify a last name',
                    })}
                    required
                    label="Last Name"
                    name="lastName"
                    size="small"
                    variant="standard"
                  />
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('email', {
                      required: 'You must specify a email',
                    })}
                    required
                    label="Email"
                    name="email"
                    size="small"
                    variant="standard"
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    {...register}
                    label="Phone"
                    name="phone"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    {...register}
                    label="Display Name"
                    name="displayName"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RolesAutoComplete userRoles={roles} selection={setRoles} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  fullWidth
                  variant="outlined"
                  onClick={cancel}>
                  cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isValid || roles.length === 0}>
                  save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </StyledContainer>
  );
}

export default UserAdd;
