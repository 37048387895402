import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import UserAvailabilityDTO from '@src/adapters/dto/user/UserAvailabilityDTO';
import { useEffect, useState } from 'react';
import {
  AvailabilityStateType,
  AvailabilityState,
} from '@src/constants/AvailabilityState';

interface Props {
  date: Date;
  availability?: UserAvailabilityDTO;
  key: string;
}

const PREFIX = 'availability';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  datePart: `${PREFIX}-datePart`,
  button: `${PREFIX}-button`,
  available: `${PREFIX}-available`,
  notAvailable: `${PREFIX}-notAvailable`,
  pencilled: `${PREFIX}-pencilled`,
  booked: `${PREFIX}-Booked`,
  notSet: `${PREFIX}-notSet`,
};

const StyledPanel = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  margin: theme.spacing(2),
  width: '358px',
  height: '74px',
  backgroundColor: theme.palette.main,
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  background:
    'linear-gradient(0deg, #0B1928, #0B1928), linear-gradient(90deg, #111114 0%, #161516 100%)',

  [`& .${classes.button}`]: {
    minWidth: '139px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '22.5px',
    letterSpacing: '0.5px',
    textAlign: 'center',
    borderRadius: '16px',
  },

  [`& .${classes.available}`]: {
    backgroundColor: '#334a54',
    borderWidth: '1px',
    borderColor: 'rgba(123, 199, 231, 0.3)',
    borderStyle: 'solid',

    color: 'white',
  },
  [`& .${classes.notAvailable}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'rgba(255, 255, 255, 0.26)',
  },
  [`& .${classes.pencilled}`]: {
    backgroundColor: 'red',
    color: 'white',
  },
  [`& .${classes.booked}`]: {
    backgroundColor: 'red',
    color: 'white',
  },
  [`& .${classes.notSet}`]: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid rgba(255, 255, 255, 0.2)',
    color: 'white',
  },
}));

const StyledDate = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  [`& .${classes.datePart}`]: {
    padding: theme.spacing(1),
  },
}));

function Slot({ date, availability, key }: Props) {
  const [state, setState] = useState<AvailabilityStateType>(
    AvailabilityState.NotSet
  );
  const [buttonText, setButtonText] = useState<string>('Not Set');

  useEffect(() => {
    if (availability) {
    } else {
      setState(AvailabilityState.NotSet);
      setButtonText('Not Set');
    }
  }, [availability]);

  const handleClick = () => {
    switch (state) {
      case AvailabilityState.NotSet:
        setState(AvailabilityState.Available);
        setButtonText('Available');
        break;
      case AvailabilityState.Available:
        setState(AvailabilityState.NotAvailable);
        setButtonText('Not Available');
        break;
      default:
        setState(AvailabilityState.NotSet);
        setButtonText('Not Set');
        break;
    }
  };

  let buttonClass = classes.button;

  switch (state) {
    case AvailabilityState.Available:
      buttonClass = `${classes.button} ${classes.available}`;
      break;
    case AvailabilityState.Booked:
      buttonClass = `${classes.button} ${classes.booked}`;
      break;
    case AvailabilityState.NotAvailable:
      buttonClass = `${classes.button} ${classes.notAvailable}`;
      break;
    case AvailabilityState.Pencilled:
      buttonClass = `${classes.button} ${classes.pencilled}`;
      break;
    default:
      buttonClass = `${classes.button} ${classes.notSet}`;
      break;
  }

  return (
    <StyledPanel>
      <StyledDate>
        <Typography className={classes.datePart}>
          {format(date, 'EEE')}
        </Typography>
        <Typography className={classes.datePart}>
          {format(date, 'dd')}
        </Typography>
        <Typography className={classes.datePart}>
          {format(date, 'MMM')}
        </Typography>
      </StyledDate>
      <Button
        className={buttonClass}
        variant="contained"
        size="large"
        onClick={handleClick}>
        {buttonText}
      </Button>
    </StyledPanel>
  );
}

export default Slot;
