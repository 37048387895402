import useApi, { ApiOptions } from '../base/useApi';

export interface Params {
  username: string;
}

export interface Result {
  nickname: string;
  avatarUrl: string;
  isAppUser: boolean;
  name: string;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/chat-info',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useUserChatInfoLazy = (params?: Params) => {
  return useApi<Params, Result>(options, params);
};

export default useUserChatInfoLazy;
