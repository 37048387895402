function trimLeft(value: string, charlist: string) {
  if (charlist === undefined) charlist = 's';

  return value.replace(new RegExp('^[' + charlist + ']+'), '');
}

function trimRight(value: string, charlist: string) {
  if (charlist === undefined) charlist = 's';

  return value.replace(new RegExp('[' + charlist + ']+$'), '');
}

function trim(value: string, charlist: string) {
  return trimRight(trimLeft(value, charlist), charlist);
}

export function buildUrl(parts: string[]): string {
  let result = '';

  for (const part of parts) {
    const urlPart = trim(part, '/');

    if (result.length === 0) {
      result = `${urlPart}`;
    } else if (result.length > 0) {
      result = `${result}/${urlPart}`;
    }
  }

  return result;
}
