import useApi, { ApiOptions } from './base/useApi';

export interface LoginParams {
  email: string;
  password: string;
  client: 'hub' | 'app';
}

export interface LoginResult {
  token: string;
  refreshToken: string;
}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/login',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
  manualErrorHandling: true,
};

const useLogin = (params?: LoginParams) => {
  options.lazy = false;
  return useApi<LoginParams, LoginResult>(options, params);
};

export const useLoginLazy = (params?: LoginParams) => {
  options.lazy = true;
  return useApi<LoginParams, LoginResult>(options, params);
};

export default useLogin;
