import { Box, LinearProgress } from '@mui/material';
import { useState } from 'react';
import Information from './components/Information';
import DepartmentList from './components/DepartmentList';
import Address from './components/Address';
import { useRegisterUserLazy } from '@src/adapters/hooks/user/useRegisterUser';
import Welcome from './components/Welcome';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import theme from '@src/theme';
import DepartmentDTO from '@src/adapters/dto/department/DepartmentDTO';
import RoleList from './components/RoleList';
import Experience from './components/Experience';
import UserRegisterExperienceDTO from '@src/adapters/dto/user/UserRegisterExperienceDTO';
import Productions from './components/Productions';
import UserRegisterAddressDTO from '@src/adapters/dto/user/UserRegisterAddressDTO';
import UserRegisterDTO from '@src/adapters/dto/user/UserRegisterDTO';

const enum screens {
  'information',
  'department',
  'role',
  'experience',
  'productions',
  'address',
  'welcome',
}

function Join() {
  const [showScreen, setShowScreen] = useState(screens.information);
  const [availableRoles, setAvailableRoles] = useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<
    DepartmentDTO[]
  >([]);

  const [user, setUser] = useState<UserRegisterDTO>();
  const { execute: register } = useRegisterUserLazy();

  const handleInformation = (user: UserRegisterDTO) => {
    setUser(user);
    setShowScreen(screens.department);
  };

  const handleDepartment = (departments: DepartmentDTO[], forward: boolean) => {
    setSelectedDepartments(departments);

    const roles = departments
      .flatMap((department) => department.roles)
      .filter((role) => role)
      .filter((value, index, self) => self.indexOf(value) === index);

    setAvailableRoles(roles);

    if (roles.length === 0) {
      setUser((currentUser) => {
        if (currentUser) {
          currentUser.departmentRoles = [];
        }
        return currentUser;
      });
    }

    if (forward) {
      if (roles.length > 0) {
        setShowScreen(screens.role);
      } else {
        setShowScreen(screens.experience);
      }
    } else {
      setShowScreen(screens.information);
    }
  };

  const handleRole = (roles: string[], forward: boolean) => {
    setUser((prevUser) => {
      if (prevUser) {
        prevUser.departmentIds = selectedDepartments.map((d) => d.id);
        prevUser.departmentRoles = roles;
      }
      return prevUser;
    });

    if (forward) {
      setShowScreen(screens.experience);
    } else {
      setShowScreen(screens.department);
    }
  };

  const handleExperience = (
    experience: UserRegisterExperienceDTO,
    forward: boolean
  ) => {
    setUser((prevUser) => {
      if (prevUser) {
        prevUser.endorsers = experience.endorsers;
        prevUser.referrers = experience.referrers;
        if (experience.yearsOfExperience) {
          prevUser.yearsOfExperience = experience.yearsOfExperience;
        }
      }
      return prevUser;
    });

    if (forward) {
      setShowScreen(screens.productions);
    } else {
      if (availableRoles.length > 0) {
        setShowScreen(screens.role);
      } else {
        setShowScreen(screens.department);
      }
    }
  };

  const handleProductions = (productions: string[], forward: boolean) => {
    setUser((prevUser) => {
      if (prevUser) {
        prevUser.productions = productions;
      }
      return prevUser;
    });
    if (forward) {
      setShowScreen(screens.address);
    } else {
      setShowScreen(screens.experience);
    }
  };

  const handleAddress = async (
    address: UserRegisterAddressDTO,
    forward: boolean
  ) => {
    const enteredUser = user;
    if (enteredUser) {
      enteredUser.address = address;
    }

    if (forward && enteredUser) {
      const result = await register({ user: enteredUser });

      if (result.registered) {
        setShowScreen(screens.welcome);
      }
    } else {
      setShowScreen(screens.productions);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          height: '72px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Logo />
      </Box>
      {showScreen !== screens.welcome && (
        <LinearProgress
          variant="determinate"
          value={showScreen * 16.66 + 16.66}
          sx={{
            height: '8px',
            marginBottom: theme.spacing(4),
            marginTop: theme.spacing(4),
          }}
        />
      )}
      {showScreen === screens.information && (
        <Information onContinue={handleInformation} user={user} />
      )}
      {showScreen === screens.department && (
        <DepartmentList
          onContinue={(departments: DepartmentDTO[]) =>
            handleDepartment(departments, true)
          }
          onBack={(departments: DepartmentDTO[]) =>
            handleDepartment(departments, false)
          }
          selectedDepartments={selectedDepartments}
        />
      )}
      {showScreen === screens.role && (
        <RoleList
          onContinue={(roles) => handleRole(roles, true)}
          onBack={(roles) => handleRole(roles, false)}
          roles={availableRoles}
          selectedRoles={
            user && user.departmentRoles ? user.departmentRoles : []
          }
        />
      )}
      {showScreen === screens.experience && (
        <Experience
          onContinue={(experience) => handleExperience(experience, true)}
          onBack={(experience) => handleExperience(experience, false)}
          experience={
            user
              ? {
                  endorsers: user.endorsers,
                  referrers: user.referrers,
                  yearsOfExperience: user.yearsOfExperience,
                }
              : undefined
          }
        />
      )}
      {showScreen === screens.productions && (
        <Productions
          productions={user && user.productions ? user.productions : []}
          onContinue={(productions: string[]) =>
            handleProductions(productions, true)
          }
          onBack={(productions: string[]) =>
            handleProductions(productions, false)
          }
        />
      )}
      {showScreen === screens.address && (
        <Address
          address={user && user.address ? user.address : undefined}
          onContinue={(address: UserRegisterAddressDTO) =>
            handleAddress(address, true)
          }
          onBack={(address: UserRegisterAddressDTO) =>
            handleAddress(address, false)
          }
        />
      )}
      {showScreen === screens.welcome && <Welcome />}
    </Box>
  );
}

export default Join;
