import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useActivationCodeLazy from '../../adapters/hooks/useActivationCode';

const PREFIX = 'ActivationCode';

const classes = {
  container: `${PREFIX}-container`,
  forgottenPassword: `${PREFIX}-forgottenPassword`,
  logo: `${PREFIX}-logo`,
  instructions: `${PREFIX}-instructions`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(3),
    minHeight: '80vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  [`& .${classes.forgottenPassword}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.logo}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.instructions}`]: {
    marginBottom: '20px',
  },
}));

interface ActivationCodeFormData {
  email: string;
  password: string;
  confirmPassword: string;
}

function ActivationCode() {
  const navigate = useNavigate();
  const { activationCode } = useParams();
  const { execute } = useActivationCodeLazy();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ActivationCodeFormData>({
    mode: 'all',
  });

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = handleSubmit(async (data) => {
    await execute({
      activationCode: activationCode ? activationCode.trim() : '',
      password: data.password.trim(),
      email: data.email.trim(),
    });

    navigate('/login');
  });

  return (
    <StyledContainer className={classes.container} maxWidth="xs">
      <Paper>
        <Box m={5}>
          <Typography className={classes.instructions}>
            Please enter your email and a password containing at least 8
            characters
          </Typography>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('email', {
                        required: 'You must specify a password',
                        minLength: {
                          value: 8,
                          message: 'Password must have at least 8 characters',
                        },
                      })}
                      required
                      label="Email"
                      name="email"
                      size="small"
                      variant="outlined"
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('password', {
                        required: 'You must specify a password',
                        minLength: {
                          value: 8,
                          message: 'Password must have at least 8 characters',
                        },
                      })}
                      required
                      label="New password"
                      name="password"
                      size="small"
                      type="password"
                      variant="outlined"
                    />
                    {errors.password && <p>{errors.password.message}</p>}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('confirmPassword', {
                        required: true,
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                      required
                      label="Confirm new password"
                      name="confirmPassword"
                      size="small"
                      type="password"
                      variant="outlined"
                    />
                    {errors.confirmPassword && (
                      <p>{errors.confirmPassword.message}</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained">
                  Set password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </StyledContainer>
  );
}

export default ActivationCode;
