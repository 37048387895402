import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ProductionDTO from '@src/adapters/dto/production/ProductionDTO';
import { useEffect, useState } from 'react';

const PREFIX = 'Template';

const classes = {
  pageTitle: `${PREFIX}-pageTitle`,
  paper: `${PREFIX}-information`,
  table: `${PREFIX}-table`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.pageTitle}`]: {
    padding: '0 20px 20px 20px',
    display: 'flex',
  },

  [`& .${classes.paper}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ProductionList() {
  // const { execute: userFind } = useFindUsersLazy();
  const [productions] = useState<ProductionDTO[]>([]);
  const [recordCount] = useState(1);
  const [rowsPerPage] = useState(5);
  const [selectedPage] = useState(1);

  // const { enqueueSnackbar } = useSnackbar();

  const changePage = (value: number) => {};

  const changeRowsPerPage = (value: number) => {};

  useEffect(() => {}, []);

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    production: ProductionDTO
  ) => {};

  return (
    <StyledBox>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="Contents table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell component="th" key="variant" align="left">
                Production Name
              </TableCell>
              <TableCell component="th" key="lastName" align="left">
                Production Company
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productions.map((production, index) => {
              return (
                <TableRow
                  hover
                  onClick={(event) => handleRowClick(event, production)}
                  role="checkbox"
                  tabIndex={-1}
                  key={index}>
                  <TableCell scope="row">{production.name}</TableCell>
                  <TableCell scope="row">{production.company.name}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={recordCount}
        rowsPerPage={rowsPerPage}
        page={selectedPage}
        onPageChange={(event, number) => changePage(number)}
        onRowsPerPageChange={(event) => {
          changeRowsPerPage(parseInt(event.target.value, 10));
          changePage(0);
        }}
      />
    </StyledBox>
  );
}

export default ProductionList;
