import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';

import useUserInfoLocal from '../../adapters/hooks/user/useUserInfoLocal';
import Avatar from './Avatar';

interface Props {
  username: string;
}

function UserChip({ username }: Props) {
  const { getUserInfo } = useUserInfoLocal();
  const [display, setDisplay] = useState('');
  const [avatarURL, setAvatarURL] = useState('');
  useEffect(() => {
    getUserInfo(username).then((ui) => {
      setDisplay(ui.name);
      setAvatarURL(ui.avatarUrl);
    });
  }, [getUserInfo, username, setDisplay]);
  return (
    <Chip
      avatar={<Avatar url={avatarURL} showOutline={false} />}
      label={display}
    />
  );
}

export default UserChip;
