import UserRegisterDTO from '@src/adapters/dto/user/UserRegisterDTO';
import useApi, { ApiOptions } from '../base/useApi';
interface Params {
  user: UserRegisterDTO;
}

interface Result {
  registered: boolean;
}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/user/register',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useRegisterUser = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useRegisterUserLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useRegisterUser;
