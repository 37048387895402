import UserProfileDTO from '../../dto/user/UserProfileDTO';
import useApi, { ApiOptions } from '../base/useApi';

interface Params {}

interface Result {
  userProfile: UserProfileDTO;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/profile-hub',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useGetUserProfileHub = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useGetUserProfileHubLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useGetUserProfileHub;
