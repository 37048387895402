import { useCallback } from 'react';
import { addMinutes } from '../../../utils/dateUtils';
import useUserChatInfoLazy from '../../hooks/user/useUserChatInfo';

const cacheEntryTtlMins = 10;
const userInfoCache: any = {};
//Seed Hidden strength user
// eslint-disable-next-line dot-notation
userInfoCache['HiddenStrength'] = {
  expiryDate: undefined,
  data: {
    avatar: 'hs',
    nickname: 'Hidden Strength',
    isAppUser: false,
    name: '',
  },
};

const useUserInfoLocal = () => {
  const { execute } = useUserChatInfoLazy();

  const getUserInfo = useCallback(
    async (
      username: string,
      refresh?: boolean
    ): Promise<{
      avatarUrl: string;
      nickname: string;
      isAppUser: boolean;
      name: string;
    }> => {
      if (!username) {
        return Promise.resolve({
          avatarUrl: '',
          nickname: '',
          isAppUser: false,
          name: '',
        });
      }
      if (
        userInfoCache[username] &&
        (userInfoCache[username].expiryDate === undefined ||
          userInfoCache[username].expiryDate > new Date()) &&
        !refresh
      ) {
        return Promise.resolve(userInfoCache[username].data);
      } else {
        const result = await execute({
          username,
        });
        userInfoCache[username] = {
          expiryDate: addMinutes(new Date(), cacheEntryTtlMins),
          data: {
            avatarUrl:
              !result.avatarUrl && !result.isAppUser ? 'hs' : result.avatarUrl,
            nickname: result.nickname,
            isAppUser: result.isAppUser,
            name: result.name,
          },
        };
        return userInfoCache[username].data;
      }
    },
    [execute]
  );
  return {
    getUserInfo,
  };
};

export default useUserInfoLocal;
