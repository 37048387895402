import { CircularProgress, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';
import { useState } from 'react';

const PREFIX = 'AutoCompleteString';

const classes = {
  searchBox: `${PREFIX}-searchBox`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.searchBox}`]: {
    width: '100%',
  },
}));

interface Props {
  id: string;
  label: string;
  options: string[];
  value: string | null;
  setValue(value: string | null): void;
  disabled?: boolean;
  splitUnderscore?: boolean;
}

function AutoCompleteString({
  id,
  label,
  options,
  value,
  setValue,
  disabled,
  splitUnderscore,
}: Props) {
  const [open, setOpen] = useState(false);
  const [loading] = useState(false);

  return (
    <Autocomplete
      id={id}
      open={open}
      disabled={disabled ? disabled : false}
      value={value}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={(event: any, value: string | null) => {
        if (value !== null) {
          setValue(value);
        } else {
          setValue(null);
        }
      }}
      getOptionLabel={(type) =>
        splitUnderscore ? type.replace('_', ' ') : type
      }
      fullWidth={true}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          autoComplete="false"
          className={classes.searchBox}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Root>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Root>
            ),
          }}
        />
      )}
    />
  );
}

export default AutoCompleteString;
