import useApi, { ApiOptions } from './base/useApi';

export interface ActivationCodeParams {
  activationCode: string;
  email: string;
  password: string;
}

export interface ActivationCodeResult {}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/activation-code',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useActivationCodeLazy = (params?: ActivationCodeParams) => {
  return useApi<ActivationCodeParams, ActivationCodeResult>(options, params);
};

export default useActivationCodeLazy;
