import { useSnackbar } from 'notistack';
import axios, { AxiosResponse } from 'axios';
import { buildUrl } from '../../../utils/urlUtils';
import { useCallback, useEffect, useState } from 'react';
import RestErrorCode from '../../../constants/RestErrorCode';

export interface ApiOptions {
  method: 'get' | 'post' | 'delete';
  baseUrl: string;
  urlPath: string;
  lazy: boolean;
  isFormData?: boolean;
  manualErrorHandling?: boolean;
}

export interface ApiInterface<P, R> {
  execute: (executeParams?: P) => Promise<R>;
  data: R | undefined;
  error: any;
  loading: boolean;
}

declare type ErrorMapping = {
  [key in RestErrorCode]: string;
};

const errorMapping: ErrorMapping = {
  UnexpectedError: 'Unknown Server Error',
  WrongCredentials: 'Invalid credentials have been entered',
  DuplicateEmail: 'This email address is already in use',
  DuplicatePhone: 'This phone number is already in use',
  AlreadyExists: 'This is a duplicate',
  UserEmailNotFound: 'Email address has not been found on an active account',
  UserActivationFailed: 'The email and activation code do not match',
  UserPasswordResetCodeNotFound:
    'Password reset code not found please request a new one',
  UserPasswordResetCodeExpired:
    'Password reset code has expired please request a new one',
};

const useApi = <P, R>(
  options: ApiOptions,
  params?: P,
  transformParams?: (p: P) => any
): ApiInterface<P, R> => {
  const [data, setData] = useState<R>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const url = buildUrl([options.baseUrl, 'api', options.urlPath]);
  const { enqueueSnackbar } = useSnackbar();

  const execute = useCallback(
    (executeParams?: P): Promise<R> => {
      return new Promise<R>(function (resolve, reject) {
        setLoading(true);

        let workingParams = executeParams;
        if (transformParams && executeParams) {
          workingParams = transformParams(executeParams);
        }

        const accessToken = localStorage.getItem('access_token');
        const connectionTimeout = 10000;
        const source = axios.CancelToken.source();

        setTimeout(() => {
          source.cancel('Timeout');
        }, connectionTimeout); // connection timeout here in ms (default 10 seconds)

        axios(url, {
          method: options.method,
          headers: {
            Accept: 'application/json',
            'Content-Type': options.isFormData
              ? 'multipart/form-data'
              : 'application/json',
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
          withCredentials: true,
          data:
            options.method === 'post'
              ? workingParams ?? params ?? {}
              : undefined,
          params:
            options.method === 'get' || options.method === 'delete'
              ? workingParams ?? params ?? {}
              : undefined,
          cancelToken: source.token,
        })
          .then(async (response: AxiosResponse<R>) => {
            setData(response.data);
            resolve(response.data);
          })
          .catch((error: any) => {
            if (options.manualErrorHandling) {
              setError(error.response);
              reject(error.response);
            } else {
              const mappedError: string | undefined =
                errorMapping[error.response?.data?.code as RestErrorCode];

              if (mappedError) {
                enqueueSnackbar(mappedError, {
                  variant: 'error',
                });
              } else {
                if (error.response?.status === 403) {
                  enqueueSnackbar('This request is forbidden', {
                    variant: 'error',
                  });
                } else {
                  enqueueSnackbar('Unknown Server Error', {
                    variant: 'error',
                  });
                }
              }
            }
          })
          .finally(() => setLoading(false));
      });
    },
    [options, params, url, transformParams, enqueueSnackbar]
  );

  useEffect(() => {
    if (!options.lazy) {
      execute();
    }
  }, [execute, options.lazy]);

  return { execute, data, error, loading };
};

export default useApi;
