import { Box, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AutoCompleteString from '../../components/utilities/AutoComplete/AutoCompleteString';
import { Roles } from '../../constants/Roles';
import SearchBox from '../../components/utilities/SearchBox';

const PREFIX = 'UserFilter';

const classes = {
  container: `${PREFIX}-container`,
  role: `${PREFIX}-role`,
  name: `${PREFIX}-name`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },

  [`& .${classes.role}`]: { width: '45%', paddingLeft: '10px' },
  [`& .${classes.name}`]: { width: '45%' },
}));

interface Props {
  setRole(value: string | null): void;
  role: string | null;
  search(value: string | null): void;
  add(): void;
}

function UserFilter({ setRole, role, search, add }: Props) {
  const [selectableRoles, setSelectableRoles] = useState<string[]>([]);

  useEffect(() => {
    setSelectableRoles(Roles);
  }, []);

  return (
    <StyledBox className={classes.container}>
      <Box className={classes.role}>
        <AutoCompleteString
          id="contentfiltertype"
          options={selectableRoles}
          setValue={setRole}
          value={role}
          label="Role"
        />
      </Box>
      <Box className={classes.name}>
        <SearchBox title="Search by name, username or ID" search={search} />
      </Box>
      <Fab color="secondary" size="small" aria-label="add" onClick={add}>
        <AddIcon />
      </Fab>
    </StyledBox>
  );
}

export default UserFilter;
