import { Routes, Route } from 'react-router-dom';
import { Routes as r } from './navigation/Routes';
import { setup } from './adapters/interceptors/retryInterceptor';
import ForgottenPassword from './pages/ForgottenPassword';
import ActivationCode from './pages/ActivationCode';
import Join from './pages/Join';

import Home from './pages/Home';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import UserManagement from './pages/UserManagement';
import AuditLog from './pages/AuditLog';
import Profile from './pages/Profile';
import RequireAuth from './navigation/RequireAuth';
import Productions from './pages/Productions';
import Availability from './pages/Availability';
import Crew from './pages/Crew';

/**
 * This convoluted way to determine the initial component is needed due to the way we are
 * applyting the environment variables at runtime using nginx
 * When doing a simple ternary operation the typescript compiler is optimizing the code
 * and replacing the ternary operation with the result at compile time.
 * */
const determineEntryComponent = (reactAppComponent: string | undefined) => {
  return reactAppComponent === 'Join' ? Join : Login;
};
const ComponentToRender = determineEntryComponent(
  process.env.REACT_APP_COMPONENT
);

console.log('Here ' + process.env.REACT_APP_COMPONENT);
function App() {
  setup();
  return (
    <main>
      <Routes>
        <Route path="/" element={<ComponentToRender />} />
        <Route path={r.Login} element={<ComponentToRender />} />
        <Route path={r.ForgottenPassword} element={<ForgottenPassword />} />
        <Route path={r.ResetPassword} element={<ResetPassword />} />
        <Route path={r.ActivationCode} element={<ActivationCode />} />
        <Route path={r.Join} element={<Join />} />
        <Route path={r.Availability} element={<Availability />} />
        <Route element={<RequireAuth />}>
          <Route path={r.Home} element={<Home />} />
          <Route path={r.Profile} element={<Profile />} />
          <Route path={r.Productions} element={<Productions />} />
          <Route path={r.Crew} element={<Crew />} />
          <Route path={r.UserManagement} element={<UserManagement />} />
          <Route path={r.AuditLog} element={<AuditLog />} />
        </Route>
        <Route path="*" element={<ComponentToRender />} />
      </Routes>
    </main>
  );
}

export default App;
