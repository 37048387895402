import useApi, { ApiOptions } from './base/useApi';

export interface LogoutParams {}

export interface LogoutResult {}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/logout',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: true,
};

export const useLogoutLazy = (params?: LogoutParams) => {
  return useApi<LogoutParams, LogoutResult>(options, params);
};

export default useLogoutLazy;
