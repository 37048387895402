import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EventLogDTO from '../../../adapters/dto/eventLog/EventLogDTO';
import UserChip from '../../../components/user/UserChip';
import { formatDate } from '../../../utils/dateUtils';

const PREFIX = 'Details';

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
  row: `${PREFIX}-row`,
  info: `${PREFIX}-info`,
  title: `${PREFIX}-title`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    height: '80vh',
  },

  [`& .${classes.list}`]: {
    height: '65vh',
    overflow: 'auto',
  },

  [`& .${classes.row}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    paddingTop: theme.spacing(3),
  },

  [`& .${classes.info}`]: {
    width: '50%',
  },

  [`& .${classes.title}`]: {
    paddingBottom: '10px',
  }
}));

interface Props {
  eventLog: EventLogDTO | undefined;
}

function Details({ eventLog }: Props) {

  return (
    <StyledBox>
      {eventLog && (
        <Box className={classes.root}>
          <Typography variant="h5">Details</Typography>
          <Box className={classes.row}>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Time</Typography>
              </Box>
              <Typography variant="body2">
                {eventLog.createdAt && formatDate(eventLog.createdAt, 'HH:mm')}
              </Typography>
            </Box>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Date</Typography>
              </Box>
              <Typography variant="body2">
                {eventLog.createdAt &&
                  formatDate(eventLog.createdAt, 'DD/MM/YYYY')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Hub Username</Typography>
              </Box>
              {eventLog.createdByUsername && (
                <UserChip username={eventLog.createdByUsername} />
              )}
            </Box>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">CYP Username</Typography>
              </Box>
              {eventLog.appUserUserName && (
                <UserChip username={eventLog.appUserUserName} />
              )}
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Event Group</Typography>
              </Box>
              <Typography variant="body2">{eventLog.group}</Typography>
            </Box>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Event Name</Typography>
              </Box>
              <Typography variant="body2">
                {eventLog.name.split('_').slice(1).join(' ')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.row}>
            <Box className={classes.info}>
              <Box className={classes.title}>
                <Typography variant="subtitle2">Event Text</Typography>
              </Box>
              <Typography variant="body2">{eventLog.text}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </StyledBox>
  );
}

export default Details;
