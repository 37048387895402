import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/hooks/useAuth';
import { useLoginLazy } from '../../adapters/hooks/useLogin';
import useCurrentUserDetailsLazy from '../../adapters/hooks/useCurrentUserDetails';
import { useEffect, useState } from 'react';
import { Routes } from '../../navigation/Routes';
import { ReactComponent as ProcedureHorizontal } from '../../assets/logo.svg';
import RestErrorCode from '../../constants/RestErrorCode';

const PREFIX = 'Login';

const classes = {
  container: `${PREFIX}-container`,
  forgottenPassword: `${PREFIX}-forgottenPassword`,
  logo: `${PREFIX}-logo`,
  innerContainer: `${PREFIX}-innerContainer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    padding: theme.spacing(3),
    minHeight: '80vh',
    alignItems: 'center',
    flexWrap: 'wrap',
    display: 'flex',
  },

  [`& .${classes.forgottenPassword}`]: {
    display: 'flex',
    justifyContent: 'center',
  },

  [`& .${classes.logo}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.innerContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  [`& .${classes.logo}`]: {
    paddingBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface LoginFormData {
  email: string;
  password: string;
}

function getDefaultRoute(roles: string[]): Routes {
  return Routes.Crew;
}

function Login() {
  const navigate = useNavigate();
  const { setAccessToken, setLoggedInUser, loggedInUser } = useAuth();
  const { execute: login, loading, error: loginError } = useLoginLazy();
  const { execute: getCurrentUser } = useCurrentUserDetailsLazy();
  const { handleSubmit, register, formState } = useForm<LoginFormData>({
    mode: 'all',
  });
  const [errorMessage, setErrorMessage] = useState<string>();

  const onSubmit = handleSubmit(async (data) => {
    setErrorMessage(undefined);
    const loginResult = await login({
      email: data.email,
      password: data.password,
      client: 'hub',
    });
    // save tokens
    setAccessToken(loginResult.token);
    // get user information
    const user = await getCurrentUser();
    setLoggedInUser(user);
  });

  useEffect(() => {
    if (loggedInUser) {
      navigate(getDefaultRoute(loggedInUser.roles ?? []));
    }
  }, [navigate, loggedInUser]);

  useEffect(() => {
    if (loginError?.data?.code === RestErrorCode.WrongCredentials) {
      setErrorMessage('The credentials you have supplied are incorrect');
    }
  }, [loginError]);

  return (
    <StyledContainer className={classes.container} maxWidth="xs">
      <Paper>
        <Box m={5} className={classes.innerContainer}>
          <Box className={classes.logo}>
            <ProcedureHorizontal title="logo" />
          </Box>
          <form onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('email', { required: true })}
                      required
                      label="Email address"
                      name="email"
                      size="small"
                      variant="outlined"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      {...register('password', { required: true })}
                      required
                      label="Password"
                      name="password"
                      size="small"
                      type="password"
                      variant="outlined"
                      autoComplete="current-password"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isValid}>
                  Log in
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.forgottenPassword}>
                <Link component={RouterLink} to="/forgotten-password">
                  Forgotten password
                </Link>
              </Grid>
              <Grid item xs={12} className={classes.forgottenPassword}>
                <Link component={RouterLink} to="/join">
                  Join
                </Link>
              </Grid>
              <Grid>
                {loading && <span>Loading</span>}
                {errorMessage && <span>{errorMessage}</span>}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </StyledContainer>
  );
}

export default Login;
