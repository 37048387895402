import UploadUrlDTO from '../../dto/UploadUrlDTO';
import useApi, { ApiOptions } from '../base/useApi';

interface Params {
  imageType: string;
}

interface Result {
  avatar: UploadUrlDTO;
  imageName: string;
}

const options: ApiOptions = {
  method: 'get',
  urlPath: '/user/avatar-upload-url',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useGetAvatarUploadUrl = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useGetAvatarUploadUrlLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useGetAvatarUploadUrl;
