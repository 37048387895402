import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, useLocation } from 'react-router-dom';

const PREFIX = 'breadcrumbs';

const BreadcrumbsContainer = styled('nav')({
  marginBottom: '1rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
});

const BreadcrumbsList = styled('ul')({
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
});

const BreadcrumbSeparator = styled('li')(({ theme }) => ({
  margin: '0 0.5rem',
  color: theme.palette.text.secondary,
}));
const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  [`&.${PREFIX}-link`]: {
    fontWeight: 'bold',
  },
}));

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');

  const decodeBreadcrumbValue = (encodedValue: string) => {
    try {
      const [displayValue] = decodeURIComponent(encodedValue).split('|');
      return displayValue;
    } catch {
      return encodedValue;
    }
  };

  return (
    <BreadcrumbsContainer>
      <BreadcrumbsList>
        <li>
          <StyledLink to="/" className={`${PREFIX}-link`}>
            Home
          </StyledLink>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const breadcrumbValue = decodeBreadcrumbValue(value);

          const isLast = index === pathnames.length - 1;

          return (
            <React.Fragment key={to}>
              <BreadcrumbSeparator>/</BreadcrumbSeparator>
              <li>
                <StyledLink to={to} className={`${PREFIX}-link`}>
                  {isLast && name
                    ? `${breadcrumbValue.replace(
                        /([a-z])([A-Z])/g,
                        '$1 $2'
                      )} / ${name}`
                    : breadcrumbValue.replace(/([a-z])([A-Z])/g, '$1 $2')}
                </StyledLink>
              </li>
            </React.Fragment>
          );
        })}
      </BreadcrumbsList>
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
