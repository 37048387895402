import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic,
} from 'axios';
import useAuthStore from '../../auth/stores/useAuthStore';
import { buildUrl } from '../../utils/urlUtils';

export const setup = () => {
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if (
      error.config &&
      !error.config.url?.includes('refresh-token') &&
      !error.config.url?.includes('login') &&
      !error.config.url?.includes('activation-code') &&
      !error.config.url?.includes('reset-password') &&
      error.response?.status === 401 && // Use the status code your server returns when token has expired
      !(error.config as any).__isRetry
    ) {
      return refreshToken(axios, error.config);
    }
    throw error;
  });

  const refreshToken = (axios: AxiosStatic, config: AxiosRequestConfig) => {
    const accessToken = localStorage.getItem('access_token');
    return new Promise((resolve, reject) => {
      const connectionTimeout = 10000;
      const source = axios.CancelToken.source();

      setTimeout(() => {
        source.cancel('Timeout');
      }, connectionTimeout); // connection timeout here in ms (default 10 seconds)

      axios
        .post(
          buildUrl([process.env.REACT_APP_API_URL!, 'api', 'refresh-token']),
          {},
          {
            withCredentials: true,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        )
        .then((res: AxiosResponse) => {
          localStorage.setItem('access_token', res.data.token);
          if (config.headers) {
            config.headers.Authorization = `Bearer ${res.data.token}`; // Attach the new token to the headers
          }
          (config as any).__isRetry = true;
          axios
            .request(config) // Repeat the initial request
            .then((result: AxiosResponse) => {
              return resolve(result);
            })
            .catch((err: AxiosError) => {
              console.log(err);
              return reject(err);
            });
        })
        .catch((err: AxiosError) => {
          // remove old access token as it didn't work for refresh token
          const { setLoggedInUser } = useAuthStore.getState();
          console.log('refresh token failed:', config.url);
          localStorage.removeItem('access_token');
          setLoggedInUser(null);
          return reject(err);
        });
    });
  };
};
