import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
interface Props {
  onContinue(productions: string[]): void;
  onBack(productions: string[]): void;
  productions: string[];
}

const PREFIX = 'register-department';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.button}`]: {
    width: '30%',
    margin: theme.spacing(1),
  },
}));
function Productions({ onBack, onContinue, productions }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [completedProductions, setCompletedProductions] = useState<
    { index: number; value: string }[]
  >([]);

  useEffect(() => {
    if (productions) {
      setCompletedProductions(
        productions.map((e, index) => {
          return { index: index, value: e };
        })
      );
    }
  }, [productions]);

  const handleProduction = (index: number, value: string) => {
    setCompletedProductions((currentItems) => {
      const itemIndex = currentItems.findIndex((item) => item.index === index);

      if (itemIndex >= 0) {
        const updatedItems = [...currentItems];
        updatedItems[itemIndex].value = value;
        return updatedItems;
      } else {
        return [...currentItems, { index, value }];
      }
    });
  };

  const handleContinue = () => {
    if (completedProductions.length < 3) {
      enqueueSnackbar(`Please complete all productions`, {
        variant: 'error',
      });
    } else {
      onContinue(completedProductions.map((r) => r.value));
    }
  };

  const handleBack = () => {
    onBack(completedProductions.map((r) => r.value));
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.subtitle}>
        Productions
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Provide the names of 3 recent productions you have worked on.
      </Typography>
      {Array.from({ length: 3 }, (_, index) => (
        <TextField
          label="Production name"
          autoComplete="off"
          fullWidth={true}
          key={`production${index}`}
          onChange={(e) => handleProduction(index, e.target.value)}
          value={
            completedProductions.find((e) => e.index === index)
              ? completedProductions.find((e) => e.index === index)?.value
              : ''
          }
        />
      ))}
      <Box className={classes.buttons}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleBack}
          className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleContinue}
          className={classes.button}>
          Continue
        </Button>
      </Box>
    </MainContainer>
  );
}

export default Productions;
