import useApi, { ApiOptions } from '../base/useApi';
interface Params {
  imageName: string;
}

interface Result {}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/user/avatar-image-name',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useSaveUserAvatarImageName = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useSaveUserAvatarImageNameLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useSaveUserAvatarImageName;
