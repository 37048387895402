import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import UserCrewMemberDTO from '@src/adapters/dto/user/UserCrewMemberDTO';
import { useGetCrewMemberLazy } from '@src/adapters/hooks/user/useGetCrewMember';
import styled from '@emotion/styled';
import { useSaveUserVerifyLazy } from '@src/adapters/hooks/user/useSaveUserVerify';

interface Props {
  id: string;
  open: boolean;
  close(verfied: boolean): void;
}

const PREFIX = 'Member';

const classes = {
  title: `${PREFIX}-title`,
  container: `${PREFIX}-container`,
  item: `${PREFIX}-item`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  entryTitle: `${PREFIX}-entryTitle`,
  entryValue: `${PREFIX}-entryValue`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.container}`]: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classes.item}`]: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
  },
  [`& .${classes.buttons}`]: {
    flex: '1',
    marginRight: '8px',
  },
  [`& .${classes.button}`]: {
    flex: '1',
    marginLeft: '8px',
  },
}));

const StyledSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledEntry = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({ padding: '10px' }));

const StyledValue = styled(Typography)(({ theme }) => ({ padding: '10px' }));

function Member({ close, id, open }: Props) {
  const [member, setMember] = useState<UserCrewMemberDTO>();
  const { execute: get } = useGetCrewMemberLazy();
  const { execute: verify } = useSaveUserVerifyLazy();

  useEffect(() => {
    get({
      id,
    }).then((result) => {
      setMember(result.member);
    });
  }, [get, id]);

  const handleClose = () => {
    close(false);
  };

  const handleVerify = () => {
    verify({ id }).then((result) => {
      if (result.verified) {
        close(true);
      }
    });
  };

  useEffect(() => {}, []);

  return (
    <StyledDialog
      open={open}
      aria-labelledby="Note Details"
      aria-describedby="Note Details"
      fullWidth={true}
      maxWidth={'md'}>
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        <Typography variant="h6">Crew Member</Typography>
      </DialogTitle>
      {member && (
        <DialogContent className={classes.container}>
          <StyledSection>
            <StyledEntry>
              <StyledTitle variant="body2">Name:</StyledTitle>
              <StyledValue variant="body1">
                {member.firstName} {member.lastName}
              </StyledValue>
            </StyledEntry>
            {/* <StyledEntry>
              <StyledTitle variant="body2">Email:</StyledTitle>
              <StyledValue variant="body1">{member.email}</StyledValue>
            </StyledEntry> */}
          </StyledSection>
          <StyledEntry>
            <StyledTitle variant="body2">Phone Number:</StyledTitle>
            <StyledValue variant="body1">{member.phoneNumber}</StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">1st Line:</StyledTitle>
            <StyledValue variant="body1">
              {member.address.firstLine}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">2nd Line:</StyledTitle>
            <StyledValue variant="body1">
              {member.address.secondLine}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Town:</StyledTitle>
            <StyledValue variant="body1">{member.address.town}</StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Postcode:</StyledTitle>
            <StyledValue variant="body1">{member.address.postcode}</StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Country:</StyledTitle>
            <StyledValue variant="body1">{member.address.country}</StyledValue>
          </StyledEntry>

          <StyledEntry>
            <StyledTitle variant="body2">Departments:</StyledTitle>
            <StyledValue variant="body1">
              {member.departments.map((d) => {
                return <Box>{d.name}</Box>;
              })}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Roles:</StyledTitle>
            <StyledValue variant="body1">
              {' '}
              {member.departmentRoles.map((r) => {
                return <Box>{r}</Box>;
              })}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Years of Experience:</StyledTitle>
            <StyledValue variant="body1">
              {member.yearsOfExperience}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Referrers:</StyledTitle>
            <StyledValue variant="body1">
              {member.referrers.map((r) => {
                return <Box>{r}</Box>;
              })}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Endorsers:</StyledTitle>
            <StyledValue variant="body1">
              {member.endorsers.map((e) => {
                return <Box>{e}</Box>;
              })}
            </StyledValue>
          </StyledEntry>
          <StyledEntry>
            <StyledTitle variant="body2">Previous Productions:</StyledTitle>
            <StyledValue variant="body1">
              {member.productions.map((p) => {
                return <Box>{p}</Box>;
              })}
            </StyledValue>
          </StyledEntry>
        </DialogContent>
      )}
      <DialogActions className={classes.buttons}>
        <Button
          onClick={handleClose}
          color="secondary"
          variant="outlined"
          className={classes.button}>
          Cancel
        </Button>
        <Button
          onClick={handleVerify}
          color="primary"
          variant="contained"
          className={classes.button}>
          Verify
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default Member;
