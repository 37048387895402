import UserDTO from '../../dto/user/UserDTO';
import useApi, { ApiOptions } from '../base/useApi';
interface Params {
  user: UserDTO;
}

interface Result {
  user: UserDTO;
}

const options: ApiOptions = {
  method: 'post',
  urlPath: '/user',
  baseUrl: process.env.REACT_APP_API_URL!,
  lazy: false,
};

export const useSaveUser = (params?: Params) => {
  options.lazy = false;
  return useApi<Params, Result>(options, params);
};

export const useSaveUserLazy = (params?: Params) => {
  options.lazy = true;
  return useApi<Params, Result>(options, params);
};

export default useSaveUser;
