import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FunctionComponent, useState } from 'react';

import useLogoutLazy from '../../adapters/hooks/useLogout';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Routes } from '../../navigation/Routes';
import { useAuth } from '../../auth/hooks/useAuth';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import Breadcrumbs from '@src/navigation/Breadcrumbs';

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  toolBar: `${PREFIX}-toolBar`,
  homeButton: `${PREFIX}-homeButton`,
  homeButtonContainer: `${PREFIX}-homeButtonContainer`,
  hide: `${PREFIX}-hide`,
  logOut: `${PREFIX}-logOut`,
  accountIcon: `${PREFIX}-accountIcon`,
  offset: `${PREFIX}-offset`,
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`,
  rightToolBar: `${PREFIX}-rightToolBar`,
  queueButton: `${PREFIX}-queueButton`,
  button: `${PREFIX}-button`,
  assignText: `${PREFIX}-assignText`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.appBar}`]: {
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '64px',
  },

  [`& .${classes.toolBar}`]: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.homeButton}`]: {
    marginRight: theme.spacing(2),
    color: 'rgba(1, 4, 13, 0.5)',
    height: '44px',
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },

  [`& .${classes.logOut}`]: {},

  [`& .${classes.accountIcon}`]: {
    fill: theme.palette.primary.light,
  },

  [`& .${classes.offset}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  [`& .${classes.rightToolBar}`]: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  [`& .${classes.queueButton}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.button}`]: {
    margin: '10px',
    paddingLeft: theme.spacing(1),
  },

  [`& .${classes.assignText}`]: {
    paddingLeft: '10px',
    color: theme.palette.success.main,
  },
}));

interface LayoutProps {
  children: any;
}

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setAccessToken, setLoggedInUser } = useAuth();
  const { execute: logout } = useLogoutLazy();

  const handleLogoClick = () => {
    return Routes.Home;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProductionsClick = () => {
    navigate(Routes.Productions);
  };

  const handleCrewClick = () => {
    navigate(Routes.Crew);
  };

  const onLogout = async () => {
    setAccessToken(undefined);
    setLoggedInUser(null);
    try {
      await logout();
    } catch (e) {
    } finally {
      navigate(Routes.Login);
    }
  };

  return (
    <Root className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar)}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleLogoClick}
            edge="start"
            className={classes.homeButton}
            size="large">
            <Logo />
          </IconButton>

          <Box>
            {/* <Button
              onClick={handleProductionsClick}
              color="info"
              variant="contained"
              size="medium">
              Productions
            </Button> */}
            <Button
              onClick={handleCrewClick}
              color="secondary"
              size="medium"
              variant="text">
              Crew
            </Button>
          </Box>
          <Box className={classes.rightToolBar}>
            <IconButton aria-label="logout" onClick={handleClick} size="large">
              <AccountCircle className={classes.accountIcon}></AccountCircle>
            </IconButton>
            <Menu
              id="toolbar-menu"
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {/*<SideMenu open={true} />*/}
      <main className={clsx(classes.content)}>
        {/* This toolbar is a hack to have the content not hidden behind the actual toolbar. Looks like a hack, but it is how they do it in the material ui site*/}
        <Toolbar />
        <Breadcrumbs />
        {children}
      </main>
    </Root>
  );
};

export default Layout;
