import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import EventLogDTO from '../../../adapters/dto/eventLog/EventLogDTO';
import EventLogFilterDTO from '../../../adapters/dto/eventLog/EventLogFilterDTO';
import { useFindEventLogLazy } from '../../../adapters/hooks/eventLog/useFindEventLog';

import Filter from './Filter';
import Results from './Results';

const PREFIX = 'SearchBody';

const classes = {
  root: `${PREFIX}-root`,
  list: `${PREFIX}-list`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    minHeight: '80vh',
  },

  [`& .${classes.list}`]: {
    height: '65vh',
    overflow: 'auto',
  },
}));

interface Props {
  selected: EventLogDTO | undefined;
  setSelected(eventLog: EventLogDTO | undefined): void;
}

function SearchBody({ selected, setSelected }: Props) {
  const [eventLogs, setEventLogs] = useState<EventLogDTO[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const [filter, setFilter] = useState<EventLogFilterDTO>();
  const [searchEnabled, setSearchEnabled] = useState(true);

  const { execute: eventLogFind } = useFindEventLogLazy();

  const search = (filter: EventLogFilterDTO) => {
    setSearchEnabled(false);
    setCurrentPage(0);
    setFilter(filter);
    setSearchEnabled(true);
  };

  useEffect(() => {
    if (searchEnabled) {
      let localFilter = filter;
      setSelected(undefined);
      if (!localFilter) {
        localFilter = {
          from: undefined,
          to: undefined,
          hubUsername: undefined,
          cypUsername: undefined,
          group: undefined,
          name: undefined,
        };
      }

      eventLogFind({
        currentPage: currentPage,
        pageSize: rowsPerPage,
        from: localFilter.from,
        to: localFilter.to,
        hubUsername: localFilter.hubUsername,
        cypUsername: localFilter.cypUsername,
        group: localFilter.group,
        name: localFilter.name,
      }).then((result) => {
        setEventLogs(result.eventLogs);
        if (result.recordCount) {
          setRecordCount(result.recordCount);
        }
      });
    }
  }, [
    eventLogFind,
    filter,
    currentPage,
    rowsPerPage,
    setSelected,
    searchEnabled,
  ]);

  return (
    <StyledBox className={classes.root}>
      <Paper elevation={8} className={classes.root}>
        <Filter search={search} />
        <Results
          eventLogs={eventLogs}
          selectedPage={currentPage}
          rowsPerPage={rowsPerPage}
          recordCount={recordCount}
          selectedEvent={selected}
          changePage={setCurrentPage}
          changeRowsPerPage={setRowsPerPage}
          setSelected={setSelected}
        />
      </Paper>
    </StyledBox>
  );
}

export default SearchBody;
