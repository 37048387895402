import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UserCrewMemberHeaderDTO from '@src/adapters/dto/user/UserCrewMemberHeaderDTO';
import { useFindCrewMembersLazy } from '@src/adapters/hooks/user/useFindCrewMembers';
import { formatDate } from '@src/utils/dateUtils';
import { useCallback, useEffect, useState } from 'react';
import Member from './Member';

interface Props {
  unverifiedOnly: Boolean;
}

const PREFIX = 'Template';

const classes = {
  pageTitle: `${PREFIX}-pageTitle`,
  paper: `${PREFIX}-information`,
  table: `${PREFIX}-table`,
  row: `${PREFIX}-row`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.pageTitle}`]: {
    padding: '0 20px 20px 20px',
    display: 'flex',
  },
  [`& .${classes.paper}`]: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  [`& .${classes.row}`]: {
    cursor: 'pointer',
  },
}));

function CrewList({ unverifiedOnly }: Props) {
  const [members, setMembers] = useState<UserCrewMemberHeaderDTO[]>([]);
  const [selectedId, setSelectedId] = useState<string>();
  const [recordCount, setRecordCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [selectedPage, setSelectedPage] = useState(0);
  const [open, setOpen] = useState(false);

  const { execute: find } = useFindCrewMembersLazy();

  const findCrewMembers = useCallback(() => {
    find({
      pageSize: rowsPerPage,
      currentPage: selectedPage,
      unverifiedOnly: unverifiedOnly,
    }).then((result) => {
      setMembers(
        result.members.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
      if (result.recordCount) {
        setRecordCount(result.recordCount);
      }
    });
  }, [find, rowsPerPage, selectedPage, unverifiedOnly]);

  useEffect(() => {
    findCrewMembers();
  }, [findCrewMembers]);

  // const { enqueueSnackbar } = useSnackbar();

  const changePage = (value: number) => {
    setSelectedPage(value);
  };

  const changeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  useEffect(() => {}, []);

  const handleClose = (verified: boolean) => {
    if (verified) {
      setMembers((currentMembers) => {
        return currentMembers.map((m) =>
          m.id === selectedId ? { ...m, isVerified: true } : m
        );
      });
    }

    setSelectedId(undefined);
    setOpen(false);
  };

  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    member: UserCrewMemberHeaderDTO
  ) => {
    setSelectedId(member.id);
    setOpen(true);
  };

  return (
    <StyledBox>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="Contents table">
          <TableHead>
            <TableRow>
              <TableCell component="th" key="firstName" align="left">
                First name
              </TableCell>
              <TableCell component="th" key="lastName" align="left">
                Last name
              </TableCell>
              <TableCell component="th" key="phoneNumber" align="left">
                Phone Number
              </TableCell>
              <TableCell component="th" key="registerDate" align="left">
                Register Date
              </TableCell>
              <TableCell component="th" key="verified" align="left">
                Verified
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members &&
              members.map((member, index) => {
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleRowClick(event, member)}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    className={classes.row}
                    selected={selectedId === member.id}>
                    <TableCell scope="row">{member.firstName}</TableCell>
                    <TableCell scope="row">{member.lastName}</TableCell>
                    <TableCell scope="row">{member.phoneNumber}</TableCell>
                    <TableCell scope="row">
                      {formatDate(member.createdAt, 'DD/MM/YYYY hh:mm')}
                    </TableCell>
                    <TableCell scope="row">
                      {member.isVerified ? 'Verified' : 'Unverified'}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={recordCount}
        rowsPerPage={rowsPerPage}
        page={selectedPage}
        onPageChange={(event, number) => changePage(number)}
        onRowsPerPageChange={(event) => {
          changeRowsPerPage(parseInt(event.target.value, 10));
          changePage(0);
        }}
      />
      {selectedId && <Member id={selectedId} open={open} close={handleClose} />}
    </StyledBox>
  );
}

export default CrewList;
