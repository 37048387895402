import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import UserRegisterAddressDTO from '@src/adapters/dto/user/UserRegisterAddressDTO';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  address: UserRegisterAddressDTO | undefined;
  onBack(address: UserRegisterAddressDTO): void;
  onContinue(address: UserRegisterAddressDTO): void;
}

const PREFIX = 'register-address';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  form: `${PREFIX}-form`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',

  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  [`& .${classes.form}`]: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    padding: '20px',
  },
  [`& .${classes.form} .MuiTextField-root`]: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.button}`]: {
    width: '30%',
    margin: theme.spacing(1),
  },
}));

function Address({ address, onBack, onContinue }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<UserRegisterAddressDTO>({
    defaultValues: {
      firstLine: '',
      secondLine: '',
      town: '',
      postcode: '',
      country: '',
    },
  });

  useEffect(() => {
    if (address) {
      setValue('firstLine', address.firstLine);
      setValue('secondLine', address.secondLine);
      setValue('town', address.town);
      setValue('postcode', address.postcode);
      setValue('country', address.country);
    }
  }, [setValue, address]);

  const handleContinue = () => {
    let errorRaised = false;
    const data = getValues();

    if (!data.firstLine) {
      enqueueSnackbar('1st Line of address is required', {
        variant: 'error',
      });
      errorRaised = true;
    }

    if (!errorRaised) {
      onContinue(data);
    }
  };

  const handleBack = () => {
    onBack(getValues());
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.title}>
        Home Address
      </Typography>
      <form className={classes.form}>
        <Controller
          name="firstLine"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField
              {...field}
              label="First line of address"
              error={!!errors.firstLine}
            />
          )}
        />
        <Controller
          name="secondLine"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Second line of address"
              error={!!errors.secondLine}
            />
          )}
        />
        <Controller
          name="town"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField {...field} label="Town" error={!!errors.town} />
          )}
        />
        <Controller
          name="postcode"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField {...field} error={!!errors.postcode} label="Postcode" />
          )}
        />
        <Controller
          name="country"
          control={control}
          rules={{ required: 'This field is required' }}
          render={({ field }) => (
            <TextField {...field} error={!!errors.country} label="Country" />
          )}
        />
        <Box className={classes.buttons}>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={handleBack}
            className={classes.button}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth={true}
            onClick={handleContinue}
            className={classes.button}>
            Continue
          </Button>
        </Box>
      </form>
    </MainContainer>
  );
}

export default Address;
