enum RestErrorCode {
  WrongCredentials = 'WrongCredentials',
  UnexpectedError = 'UnexpectedError',
  DuplicateEmail = 'DuplicateEmail',
  DuplicatePhone = 'DuplicatePhone',
  AlreadyExists = 'AlreadyExists',
  UserEmailNotFound = 'UserEmailNotFound',
  UserActivationFailed = 'UserActivationFailed',
  UserPasswordResetCodeNotFound = 'UserPasswordResetCodeNotFound',
  UserPasswordResetCodeExpired = 'UserPasswordResetCodeExpired',
}

export default RestErrorCode;
