import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 8,
  typography: {
    fontFamily: 'DM Sans',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '22.5px',
      letterSpacing: '0.5px',
    },
    h2: {
      color: '#696969',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22.5px',
      letterSpacing: '0.5px',
    },
    h3: {
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '56px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    h4: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '42px',
      letterSpacing: '0.25px',
      textAlign: 'left',
    },
    h5: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '28px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '22px',
      letterSpacing: '0.1px',
      textAlign: 'left',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
    button: {
      large: {
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0.46px',
        textAlign: 'left',
      },
      medium: {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.4px',
        textAlign: 'left',
      },
      small: {
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 800,
        lineHeight: '22px',
        letterSpacing: '0.46px',
        textAlign: 'left',
      },
    },
    caption: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
    overline: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15px',
      textAlign: 'left',
    },
  },
  palette: {
    primary: {
      main: '#A3E5F4',
      dark: '#4842F0',
      light: '#CDCEFE',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#5C5C5C',
      dark: '#6AC8E6',
      light: '#B1B1B1',
      contrastText: '#fff',
    },
    text: {
      primary: '#fff',
      secondary: '#878787',
      disabled: 'rgba(19,29,41,0.38)',
    },
    info: {
      main: 'rgba(65, 135, 169, 0.2)',
      dark: '#1976D2',
      light: '#64B5F6',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#0F0F0F',
    },
    error: {
      main: '#CF3A2F',
      dark: '#990A00',
      light: '#FF7066',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFCD66',
      dark: '#DD8502',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#04C89D',
      light: '#9BFDD4',
      dark: '#13B18E',
    },
    divider: '#EAEAEA',
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '150%',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '10px 0',
          border: '2px solid',
          borderColor: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
          color: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
          borderImageSource: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
          borderImageSlice: 1,
          borderRadius: '8px',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {},
            '&:hover fieldset': {},
            '&.Mui-focused fieldset': {},
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow:
            '0px 1px 5px 0px #0000001F, box-shadow: 0px 2px 2px 0px #00000024, box-shadow: 0px 3px 1px -2px #00000033',
          padding: '8px 22px',
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
          flex: 'none',
          flexGrow: 0,
          margin: '0px 0px',
          textTransform: 'none',
          weight: 600,
        },
        containedPrimary: {
          background: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
        },
        sizeLarge: {
          fontSize: '15px',
          lineHeight: '26px',
          letterSpacing: '0.46px',
        },
        text: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          '&:hover': {
            color: 'white',
          },
        },
        outlined: {
          '&:disabled': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          height: '36px',
          width: '250px',
          borderRadius: '4px',
        },
        groupedContained: {
          backgroundColor: 'red',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow:
            '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
        },
        secondary: {
          color: '#FFFFFF',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.54)',
          '&$disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
              background: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
              borderRadius: '4px',
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: '#fff',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0F0F0F',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '19px',
          letterSpacing: '0.15px',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          height: '56px',
          borderTop: '1px solid rgba(224, 224, 224, 1)',
          borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#0F0F0F',
          boxShadow:
            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          boxShadow: 'none',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '8px',

          background: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
        },
        bar: {
          background: 'linear-gradient(90deg, #A3E5F4 0%, #4187A9 100%)',
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;
