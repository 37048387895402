import create, { State } from 'zustand';

interface AuthState extends State {
  loggedInUser: any;
  setLoggedInUser: (user: any) => void;
}

const useAuthStore = create<AuthState>((set) => ({
  loggedInUser: undefined,
  setLoggedInUser: (user: any) => set((state) => ({ loggedInUser: user })),
}));

export default useAuthStore;
