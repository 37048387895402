import { useEffect, useState } from 'react';

import useUserInfoLocal from '../../adapters/hooks/user/useUserInfoLocal';
import Avatar from './Avatar';

interface Props {
  username: string;
  showOutline?: boolean;
}

function UserAvatar({ username, showOutline }: Props) {
  const { getUserInfo } = useUserInfoLocal();
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    getUserInfo(username).then((ui) => {
      setAvatarUrl(ui.avatarUrl);
    });
  }, [getUserInfo, username, setAvatarUrl]);

  return <Avatar url={avatarUrl} showOutline={showOutline ?? true} />;
}

export default UserAvatar;
