import { Box, styled, Typography } from '@mui/material';

const PREFIX = 'register-welcome';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

function Welcome() {
  return (
    <MainContainer>
      <Typography variant="h5" className={classes.title}>
        Thanks for applying to join Glue Crew
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        We will review your application, if approved, you will start to receive
        regular SMS messages with a link to update your availability for the
        following 14 days.
      </Typography>
    </MainContainer>
  );
}

export default Welcome;
