import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Role from './Role';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
interface Props {
  onContinue(selectedRoles: string[]): void;
  onBack(selectedRoles: string[]): void;
  roles: string[];
  selectedRoles: string[];
}

const PREFIX = 'register-department';

const classes = {
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  [`& .${classes.title}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  [`& .${classes.subtitle}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  [`& .${classes.buttons}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  [`& .${classes.button}`]: {
    width: '30%',
    margin: theme.spacing(1),
  },
}));
function RoleList({ onContinue, onBack, roles, selectedRoles }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [currentSelectedRoles, setCurrentSelecetdRoles] = useState<string[]>(
    []
  );

  useEffect(() => {
    setCurrentSelecetdRoles(selectedRoles);
  }, [selectedRoles]);

  const handleCheckChanged = (
    role: { value: string; checked: boolean },
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let prevRoles = [...currentSelectedRoles];

    if (event.target.checked) {
      prevRoles.push(role.value);
    } else {
      prevRoles = prevRoles.filter((r) => r !== role.value);
    }
    setCurrentSelecetdRoles(prevRoles);
  };

  const handleContinue = () => {
    if (currentSelectedRoles.length < 1) {
      enqueueSnackbar(`At least one role is required`, {
        variant: 'error',
      });
    } else {
      onContinue(currentSelectedRoles);
    }
  };

  return (
    <MainContainer>
      <Typography variant="h5" className={classes.subtitle}>
        Select all roles that apply to you
      </Typography>
      {roles &&
        roles.map((r) => {
          return (
            <Role
              checkChanged={handleCheckChanged}
              role={{
                value: r,
                checked: currentSelectedRoles.find((csr) => csr === r)
                  ? true
                  : false,
              }}
            />
          );
        })}

      <Box className={classes.buttons}>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={() => onBack(currentSelectedRoles)}
          className={classes.button}>
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth={true}
          onClick={handleContinue}
          className={classes.button}>
          Continue
        </Button>
      </Box>
    </MainContainer>
  );
}

export default RoleList;
