import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import UserDTO from '../../adapters/dto/user/UserDTO';
import { Chip } from '@mui/material';
import { Role } from '../../constants/Roles';
import UserAvatar from '../../components/user/UserAvatar';

const PREFIX = 'UserList';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
  header: `${PREFIX}-header`,
  headerCell: `${PREFIX}-headerCell`,
  tableCell: `${PREFIX}-tableCell`,
  roleChip: `${PREFIX}-roleChip`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.table}`]: {
    minWidth: 750,
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  [`& .${classes.header}`]: {
    paddingTop: '10px',
  },

  [`& .${classes.headerCell}`]: {},
  [`& .${classes.tableCell}`]: { height: '60px' },
  [`& .${classes.roleChip}`]: { margin: '10px' },
}));

interface Props {
  users: UserDTO[];
  selectedPage: number;
  rowsPerPage: number;
  recordCount: number;
  selectedUsers: UserDTO[];
  changePage(value: number): void;
  changeRowsPerPage(value: number): void;
  setSelectedUsers(users: UserDTO[]): void;
}

function UserList({
  users,
  selectedPage,
  rowsPerPage,
  recordCount,
  setSelectedUsers,
  selectedUsers,
  changePage: setPage,
  changeRowsPerPage: setRowsPerPage,
}: Props) {
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = [...users];
      setSelectedUsers(newSelecteds);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleRowClick = (event: React.MouseEvent<unknown>, user: UserDTO) => {
    let workingArray = [];
    if (!selectedUsers.some((u) => u.id === user.id)) {
      workingArray.push(user);
    }

    setSelectedUsers(workingArray);
  };

  const handleCellClicked = (
    event: React.MouseEvent<unknown>,
    user: UserDTO
  ) => {
    let workingArray = [...selectedUsers];

    if (selectedUsers.some((c) => c.id === user.id)) {
      workingArray = selectedUsers.filter((c) => c.id !== user.id);
    } else {
      workingArray.push(user);
    }
    setSelectedUsers(workingArray);
    event.stopPropagation();
  };

  const isSelected = (user: UserDTO) => {
    return selectedUsers.some((u) => u.id === user.id);
  };

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="Contents table">
          <TableHead>
            <TableRow>
              <TableCell component="th" padding="checkbox" size="small">
                <Checkbox
                  indeterminate={
                    selectedUsers.length > 0 &&
                    selectedUsers.length < users.length
                  }
                  checked={
                    selectedUsers.length > 0 &&
                    selectedUsers.length === users.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all contents on page' }}
                />
              </TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell component="th" key="displayName" align="left">
                Display name
              </TableCell>
              <TableCell component="th" key="lastName" align="left">
                Last name
              </TableCell>
              <TableCell component="th" key="firstName" align="left">
                First Name
              </TableCell>
              <TableCell component="th" key="username" align="left">
                Username
              </TableCell>
              <TableCell component="th" key="roles" align="left">
                Roles
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => {
              const isItemSelected = isSelected(user);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleRowClick(event, user)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                      onClick={(e) => handleCellClicked(e, user)}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <UserAvatar username={user.username!} />
                  </TableCell>
                  <TableCell scope="row" padding="none">
                    {user.displayName}
                  </TableCell>
                  <TableCell scope="row" padding="none">
                    {user.lastName}
                  </TableCell>
                  <TableCell
                    scope="row"
                    padding="none"
                    className={classes.tableCell}>
                    {user.firstName}
                  </TableCell>
                  <TableCell
                    scope="row"
                    padding="none"
                    className={classes.tableCell}>
                    {user.username}
                  </TableCell>
                  <TableCell scope="row" padding="none">
                    {user.roles &&
                      user.roles.length > 0 &&
                      user.roles.map((r, index) => {
                        const role = r as Role;
                        return (
                          <Chip
                            label={role}
                            key={'chip' + index.toString()}
                            className={classes.roleChip}
                          />
                        );
                      })}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={recordCount}
        rowsPerPage={rowsPerPage}
        page={selectedPage}
        onPageChange={(event, number) => setPage(number)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Root>
  );
}

export default UserList;
