import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EventLogDTO from '../../../adapters/dto/eventLog/EventLogDTO';
import { formatDate } from '../../../utils/dateUtils';
import UserChip from '../../../components/user/UserChip';

const PREFIX = 'Results';

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
  tableCell: `${PREFIX}-tableCell`,
  header: `${PREFIX}-header`,
};

const Root = styled('div')(({ theme: Theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.table}`]: {
    width: '100%',
  },

  [`& .${classes.visuallyHidden}`]: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  [`& .${classes.tableCell}`]: {
    paddingLeft: '16px',
  },

  [`& .${classes.header}`]: {
    // paddingTop: '10px',
  },
}));

interface Props {
  eventLogs: EventLogDTO[];
  selectedPage: number;
  rowsPerPage: number;
  recordCount: number;
  selectedEvent: EventLogDTO | undefined;
  changePage(value: number): void;
  changeRowsPerPage(value: number): void;
  setSelected(AuditEvent: EventLogDTO): void;
}

function Results({
  eventLogs,
  selectedPage,
  rowsPerPage,
  recordCount,
  setSelected,
  selectedEvent,
  changePage: setPage,
  changeRowsPerPage: setRowsPerPage,
}: Props) {
  const handleRowClick = (
    event: React.MouseEvent<unknown>,
    auditEvent: EventLogDTO
  ) => {
    setSelected(auditEvent);
  };

  const isSelected = (eventLog: EventLogDTO) => {
    return selectedEvent === eventLog;
  };

  return (
    <Root className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="Contents table">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell key="time">Time</TableCell>
              <TableCell key="date">Date</TableCell>
              <TableCell key="username">Username</TableCell>
              <TableCell key="username">App User</TableCell>
              <TableCell key="group">Event Group</TableCell>
              <TableCell key="name">Event Name</TableCell>
              <TableCell key="text">Event Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventLogs.map((eventLog, index) => {
              const isItemSelected = isSelected(eventLog);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleRowClick(event, eventLog)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={index}
                  selected={isItemSelected}>
                  <TableCell component="th" id={labelId} scope="row">
                    {eventLog.createdAt &&
                      formatDate(eventLog.createdAt, 'HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    align="left">
                    {eventLog.createdAt &&
                      formatDate(eventLog.createdAt, 'DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    {eventLog.createdByUsername && (
                      <UserChip username={eventLog.createdByUsername} />
                    )}
                  </TableCell>
                  <TableCell>
                    {eventLog.appUserUserName && (
                      <UserChip username={eventLog.appUserUserName} />
                    )}
                  </TableCell>
                  <TableCell align="left">{eventLog.group}</TableCell>
                  <TableCell align="left">
                    {eventLog.name.split('_').slice(1).join(' ')}
                  </TableCell>
                  <TableCell align="left">{eventLog.text}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={recordCount}
        rowsPerPage={rowsPerPage}
        page={selectedPage}
        onPageChange={(event, number) => setPage(number)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </Root>
  );
}

export default Results;
