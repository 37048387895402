import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserDTO from '../../adapters/dto/user/UserDTO';
import { useSaveUserLazy } from '../../adapters/hooks/user/useSaveUser';
import RolesAutoComplete from '../../components/user/RolesAutoComplete';
import { useSnackbar } from 'notistack';

const PREFIX = 'UserEdit';

const classes = {
  container: `${PREFIX}-container`,
  forgottenPassword: `${PREFIX}-forgottenPassword`,
  logo: `${PREFIX}-logo`,
  instructions: `${PREFIX}-instructions`,
  header: `${PREFIX}-header`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.container}`]: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  [`& .${classes.forgottenPassword}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.logo}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.instructions}`]: {
    marginBottom: '20px',
  },

  [`& .${classes.header}`]: { paddingBottom: '20px' },
}));

interface Props {
  user: UserDTO;
  saved(user: UserDTO): void;
  cancel(): void;
  updated(dirty: Boolean): void;
}

interface UserAddFormData {
  patchworkId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  tags: string[];
  displayName: string;
}

function UserEdit({ saved, cancel, updated, user }: Props) {
  const { execute: userSave } = useSaveUserLazy();
  const [roles, setRoles] = useState<string[]>(user.roles);
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm<UserAddFormData>({
    mode: 'all',
  });

  // const password = useRef({});
  // password.current = watch('password');

  const onSubmit = handleSubmit(async (data) => {
    let workingUser: UserDTO = {
      id: user.id,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.email,
      roles,
      patchworkId: data.patchworkId,
      displayName: data.displayName,
      phone: data.phone,
    };

    try {
      const savedUser = await userSave({ user: workingUser });
      saved(savedUser.user);
    } catch (err: any) {
      enqueueSnackbar(err.message ? err.message : err, { variant: 'error' });
    }
  });

  return (
    <StyledContainer className={classes.container}>
      <Box>
        <Typography variant="h5" className={classes.header}>
          Details
        </Typography>
        <form onSubmit={onSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    defaultValue={user.username}
                    name="username"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Patchwork Id (optional)"
                    defaultValue={user.patchworkId}
                    {...register}
                    name="patchworkId"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('firstName', {
                      required: 'You must specify a first name',
                    })}
                    defaultValue={user.firstName}
                    required
                    label="First Name"
                    name="firstName"
                    size="small"
                    variant="standard"
                  />
                  {errors.firstName && <p>{errors.firstName.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    defaultValue={user.lastName}
                    {...register('lastName', {
                      required: 'You must specify a last name',
                    })}
                    required
                    label="Last Name"
                    name="lastName"
                    size="small"
                    variant="standard"
                  />
                  {errors.lastName && <p>{errors.lastName.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    {...register('email', {
                      required: 'You must specify a email',
                    })}
                    required
                    defaultValue={user.emailAddress}
                    label="Email"
                    name="email"
                    size="small"
                    variant="standard"
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    {...register('phone')}
                    label="Phone"
                    defaultValue={user.phone}
                    name="phone"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    {...register('displayName')}
                    label="Display Name"
                    defaultValue={user.displayName}
                    name="displayName"
                    size="small"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <RolesAutoComplete
                    userRoles={user.roles}
                    selection={setRoles}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  color="secondary"
                  fullWidth
                  variant="outlined"
                  onClick={cancel}>
                  cancel
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={!formState.isValid || roles.length === 0}>
                  save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>
    </StyledContainer>
  );
}

export default UserEdit;
